import React, { useContext, useState, useEffect } from 'react'
import { FormContext } from '../../../utils/formContext'
import CheckList from '../components/CheckList'

export default () => {
  const form = useContext(FormContext)
  const arr = ['Serviço', 'Comércio', 'Ambos']
  const [active, setActive] = useState(arr.indexOf(form.selected))

  useEffect(() => {
    form.setSelected(arr[active])
  }, [active, arr, form])

  return <CheckList arr={arr} active={active} setActive={setActive} />
}
