import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { SliderContext } from '../../utils/sliderContext'
import { ModalContext } from '../../utils/modalContext'

const arr = [
  '/',
  '/o-que-somos',
  '/reducao-de-custos-e-tempo',
  '/foco-no-core-business',
  '/organizacao-e-execucao',
  '/mais-capacidade-e-eficiencia',
  '/fale-com-a-gente',
]

let QUIET_PERIOD = 1300

export default ({ children }) => {
  const { modalOpen } = useContext(ModalContext)

  const [lastTransition, setLastTransition] = useState()
  const history = useHistory()
  const location = useLocation()
  const slider = useContext(SliderContext)

  const handleWheel = (e, history) => {
    if (window.innerWidth > 414) !modalOpen && wheelActive(e, history)
  }

  const wheelActive = (e, history) => {
    let timeNow = new Date().getTime()
    const i = arr.indexOf(location.pathname)

    const direction = Math.max(
      -1,
      Math.min(1, -e.nativeEvent.deltaY || -e.nativeEvent.detail)
    )

    if (timeNow - lastTransition < QUIET_PERIOD) return

    if (direction === 1) {
      slider.paginate(1)
      prevItem(history, i)
    } else {
      slider.paginate(-1)
      nextItem(history, i)
    }

    setLastTransition(timeNow)
  }

  const nextItem = (history, i) =>
    i < arr.length - 1
      ? history.push(arr[i + 1])
      : console.log('END OF ARRAY: ', arr[i])

  const prevItem = (history, i) =>
    i > 0 ? history.push(arr[i - 1]) : console.log('INIT OF ARRAY: ', arr[i])

  return (
    <Container
      large={window.innerWidth > 414}
      onWheel={e => handleWheel(e, history)}
    >
      {children}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  /* overflow: hidden; */
  ${props => props.large && `overflow: hidden;`}
`
