import styled from 'styled-components'
import { px, color } from '../styles'

export default styled.div`
  width: ${props => (props.width ? px(props.width) : px(408))};
  height: ${props => (props.height ? px(props.height) : px(572.25))};
  background-color: ${props =>
    props.color ? color[props.color] : color.greenDark};

  position: absolute;
  top: ${props => (props.top ? px(props.top) : px(203))};
  left: ${props => (props.left ? px(props.left) : px(50))};
`
