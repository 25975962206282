import styled from 'styled-components'
import { px, color } from '../styles'

export default styled.div`
  width: ${props => (props.width ? px(props.width) : px(360))};
  height: ${props => (props.height ? px(props.height) : px(442))};

  ${props =>
    `border: ${props.border ? px(props.border) : px(10)} solid ${
      props.color ? color[props.color] : color.greenLight
    };`};

  position: absolute;
  opacity: ${props => props.opacity};
  top: ${props => px(props.top)};
  left: ${props => px(props.left)};
  background-color: ${props => color[props.backgroundColor]};
`
