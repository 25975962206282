import React from 'react'
import styled, { css } from 'styled-components'
import { px, color } from '../../../styles'
import { motion } from 'framer-motion'

export default ({ children, ...props }) => (
  <Input {...props} placeholder={children} />
)

const Mixin = css`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: normal;
  font-size: ${px(18)};
  align-items: center;
  color: ${color.greenDark};
`

const Input = styled(motion.input)`
  position: absolute;
  top: ${px(52)};
  width: 100%;
  height: ${px(56)};
  opacity: 0.4;
  box-sizing: border-box;
  padding-left: ${px(20)};
  ${Mixin};

  &::placeholder {
    ${Mixin};
  }
`
