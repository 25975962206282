import React from 'react'
import { Title, Paragraph } from '../components/Texts'
import { Left, Right } from '../components/pages/Pages'
import { Blanc, Rectangle, Cellphone, Icon } from '../components'
import { Helmet } from 'react-helmet'

export default () => (
  <>
    <Helmet>
      <title>Foco no Core Business</title>
    </Helmet>
    <Left>
      <Blanc color='white' width={432} height={645} top={228} left={130} />
      <Rectangle
        width={392}
        height={562}
        top={267}
        left={130}
        color='salmonLight'
      />
      <Cellphone top={156} left={118} name='page4' />
    </Left>

    <Right>
      <Blanc color='greenDark' width={432} height={380} top={160} left={130} />
      <Title top={158} left={60} color='greenLight' width={386} fontSize={44}>
        Foco no <br /> Core Business
      </Title>

      <Paragraph
        top={301}
        left={60}
        lineHeight={30}
        width={403}
        color='white'
        opacity={0.7}
      >
        Tenha mais tempo para focar no que a sua empresa faz de melhor; Com a
        Quilha Rotinas Financeiras, você não precisa mais gastar tempo com
        tarefas financeiras que o sugam tempo e esforço que você poderia estar
        dedicando a outras partes da sua empresa.
      </Paragraph>

      <Icon top={321} left={497} name='four1' />
      <Icon top={503} left={497} name='four2' />
      <Icon top={685} left={497} name='four3' />
    </Right>
  </>
)
