import React from 'react'
import { Paragraph } from '../../Texts'

export default ({ activeStep, down }) => (
  <Paragraph
    motion
    sans
    fontSize={14}
    color='salmonLight'
    top={down ? 136 : 96}
    lineHeight={20}
    transition={1}
  >
    {activeStep + 1} de 5 passos
  </Paragraph>
)
