import React from 'react'
import styled from 'styled-components'
import { pm, alignment, direction } from '../../../styles'
import Iphone from '../../../assets/iphone.png'

export default props => (
  <Cellphone {...props}>
    <Video autoPlay loop muted inline playsInline>
      <Source
        src={require(`../../../assets/${props.name}.mp4`)}
        type='video/mp4'
      />
    </Video>
    <Frame src={Iphone} alt='Frame' />
  </Cellphone>
)

const Frame = styled.img`
  width: 100%;
  height: 100%;
`

const Cellphone = styled.div`
  width: ${pm(235.98)};
  height: ${pm(476.34)};

  position: absolute;
  top: ${props => pm(props.top)};
  left: ${props => pm(props.left)};

  ${alignment()};
  ${direction()};
`

const Source = styled.source`
  width: ${pm(210)};
  height: ${pm(440)};
  position: absolute;
  border-radius: ${pm(30)};
`

const Video = styled.video`
  width: ${pm(210)};
  height: ${pm(440)};
  position: absolute;
  border-radius: ${pm(30)};
`
