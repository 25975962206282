import React from 'react'
import styled, { keyframes } from 'styled-components'
import { px, color } from '../../styles'

export default ({ delay, onClick, children }) => {
  return (
    <Item delay={delay} onClick={onClick}>
      <h2>{children}</h2>
    </Item>
  )
}

const appear = keyframes` 
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
`

const Item = styled.div`
  padding-left: ${px(25)};
  padding-right: ${px(25)};

  text-align: center;

  opacity: 0;
  animation: 1s ${appear} forwards;
  animation-delay: ${props => props.delay};

  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: ${px(23)};
  line-height: ${px(47)};

  cursor: pointer;

  &:hover {
    border-bottom: ${px(3)} solid ${color.orangeDark};
  }
`
