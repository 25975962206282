import React from 'react'
import styled, { css } from 'styled-components'
import { useLocation } from 'react-router-dom'
import { px, color, direction, alignment } from '../../styles'
import path from '../../utils/paths'

export default ({ onClick, open }) => {
  const { pathname } = useLocation()

  const obj = path.find(p => p.path === pathname)

  return (
    <Container open={open} onClick={onClick}>
      <LineTop open={open} color={obj.menuColor} />
      <LineMiddle open={open} color={obj.menuColor} />
      <LineBottom open={open} color={obj.menuColor} />
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  top: ${px(35)};
  right: ${px(30)};
  z-index: 99;
  opacity: 0.9;
  display: flex;
  align-items: center;
  cursor: pointer;

  width: ${px(41)};
  height: ${props => (props.open ? px(40) : px(20))};

  ${direction('column')};
  ${alignment({ main: 'space-between', cross: 'flex-start' })};
`

const Line = css`
  height: ${px(2)};
  transition: 'all 0.2s ease';
  transition: 0.3s;
`

const LineTop = styled.div`
  ${Line};
  width: ${px(40)};
  background-color: ${props => (props.open ? color.white : color[props.color])};

  transform: ${props =>
    props.open ? `translateY(${px(13)}) rotate(45deg)` : 'none'};
`
const LineMiddle = styled.div`
  ${Line};
  width: ${px(24)};
  background-color: ${props => (props.open ? color.white : color[props.color])};

  opacity: ${props => (props.open ? 0 : 1)};

  transform: ${props => (props.open ? `translateX(${px(-10)})` : 'none')};
`
const LineBottom = styled.div`
  ${Line};
  width: ${props => (props.open ? px(40) : px(30))};
  background-color: ${props => (props.open ? color.white : color[props.color])};

  transform: ${props =>
    props.open ? `translateY(${px(-24)}) rotate(-45deg)` : 'none'};
`
