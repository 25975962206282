import React from 'react'
import { Left, Right } from '../components/pages/Pages'
import { Title, Paragraph } from '../components/Texts'
import { Blanc, Cellphone, Rectangle, Icon } from '../components'
import { Helmet } from 'react-helmet'

export default () => (
  <>
    <Helmet>
      <title>Organização e Execução</title>
    </Helmet>
    <Left>
      <Blanc color='salmonLight' width={400} top={230} height={420} left={40} />
      <Blanc color='salmonLight' width={300} top={150} height={100} left={40} />
      <Title top={158} left={60} color='orangeDark'>
        Organização <br /> e Execução
      </Title>

      <Paragraph top={307} left={60} lineHeight={30} width={380}>
        Tenha todas as informações financeiras da sua empresa na palma da sua
        mão de forma organizada e fácil. Encontre onde está aquele comprovante
        rapidamente ou se aquela conta foi realmente paga. Tenha relatórios
        detalhados sobre a movimentação bancária da sua empresa sem preocupação
        e informações precisas para realização de análises sobre o seu negócio.
      </Paragraph>

      <Icon top={249} left={560} name='five1' />
      <Icon top={459} left={560} name='five2' />
      <Icon top={653} left={560} name='five3' />
    </Left>

    <Right>
      <Blanc color='white' width={404} top={146} height={720} left={155} />
      <Rectangle top={267} left={167} width={392} height={562} opacity={0.2} />
      <Cellphone top={156} left={155} name='page5' />
    </Right>
  </>
)
