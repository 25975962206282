import React, { useContext, Fragment } from 'react'
import styled from 'styled-components'
import { pm, alignment, direction } from '../../../../../styles'
import { FormContext } from '../../../../../utils/formContext'
import Vai from '../../../../../assets/arrow_vai.svg'
import Check from '../../../../../assets/check.svg'
import { motion } from 'framer-motion'

export default ({ onClick }) => {
  const form = useContext(FormContext)

  return (
    <Fragment>
      {form.activeStep === 5 ? (
        <Button onClick={onClick} padding={42}>
          <p>Pronto, concluir</p>
          <Image src={Check} alt='check' />
        </Button>
      ) : (
        <Button onClick={onClick} padding={70}>
          <p>Próximo</p> <Image src={Vai} alt='forward' />
        </Button>
      )}
    </Fragment>
  )
}

const Button = styled(motion.div)`
  height: ${pm(48)};
  width: ${pm(238)};

  position: absolute;

  font-family: 'IBM Plex Sans', sans-serif;

  color: white;
  font-size: ${pm(16)};
  font-weight: bold;

  ${direction()};
  ${alignment({ main: 'space-between' })}

  padding-left: ${pm(70)};
  padding-left: ${props => pm(props.padding)};
  padding-right: ${props => pm(props.padding)};


  text-decoration: none;

  position: absolute;
  bottom: 0;
  left: ${pm(41)}; // 69 quando fora

  border: ${pm(1)} solid rgba(255, 255, 255, 0.6);
`

const Image = styled.img`
  width: ${pm(24)};
  height: ${pm(24)};
`
