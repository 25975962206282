import React from 'react'
import styled from 'styled-components'
import { pm, color } from '../../../../styles'

import path from '../../../../utils/pathsMobile'

export default ({ pathname }) => {
  const obj = path.find(p => p.path === pathname)

  return obj.logoSize === 'big' ? (
    <Svg
      top={50}
      left={24}
      width='250'
      height='51'
      viewBox='0 0 250 51'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M171.699 38.7555V27.868H178.188V29.0223H173.01V32.6724H177.736V33.8264H173.01V38.7555H171.699Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M180.917 28.8198C180.647 28.8198 180.452 28.7546 180.332 28.6248C180.213 28.4949 180.153 28.3259 180.153 28.1177V27.9148C180.153 27.707 180.213 27.538 180.332 27.4081C180.452 27.2782 180.647 27.2131 180.917 27.2131C181.188 27.2131 181.383 27.2782 181.502 27.4081C181.621 27.538 181.681 27.707 181.681 27.9148V28.1177C181.681 28.3259 181.622 28.4949 181.502 28.6248C181.383 28.7546 181.188 28.8198 180.917 28.8198ZM180.293 30.7069H181.541V38.7555H180.293V30.7069Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M184.193 38.7555V30.707H185.441V32.0024H185.503C185.7 31.5496 185.979 31.1892 186.338 30.9215C186.697 30.6538 187.177 30.52 187.781 30.52C188.612 30.52 189.27 30.7877 189.754 31.3231C190.237 31.8589 190.479 32.6153 190.479 33.5927V38.7555H189.231V33.8004C189.231 32.3516 188.623 31.6272 187.406 31.6272C187.156 31.6272 186.915 31.6587 186.681 31.7211C186.447 31.7838 186.236 31.8777 186.049 32.0028C185.862 32.1274 185.714 32.2865 185.605 32.4795C185.495 32.672 185.441 32.8986 185.441 33.1591V38.7555H184.193Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M198.902 38.7555C198.434 38.7555 198.099 38.6308 197.896 38.3811C197.693 38.1318 197.566 37.8197 197.514 37.4453H197.435C197.259 37.9444 196.967 38.3188 196.562 38.5685C196.156 38.8178 195.673 38.9425 195.111 38.9425C194.259 38.9425 193.596 38.7243 193.123 38.2876C192.649 37.8509 192.413 37.2579 192.413 36.5095C192.413 35.7503 192.691 35.1682 193.247 34.7626C193.804 34.357 194.669 34.1541 195.845 34.1541H197.435V33.3585C197.435 32.7867 197.279 32.35 196.967 32.0483C196.656 31.747 196.177 31.596 195.533 31.596C195.043 31.596 194.635 31.7051 194.308 31.9236C193.981 32.1418 193.708 32.4331 193.489 32.7971L192.74 32.0951C192.959 31.6583 193.307 31.2867 193.786 30.9799C194.264 30.6734 194.867 30.52 195.595 30.52C196.572 30.52 197.331 30.7585 197.872 31.236C198.413 31.7131 198.683 32.3772 198.683 33.2278V37.6479H199.604V38.7555H198.902V38.7555ZM195.314 37.882C195.626 37.882 195.912 37.8457 196.172 37.773C196.432 37.7002 196.656 37.596 196.843 37.4609C197.03 37.3258 197.176 37.17 197.279 36.993C197.383 36.8164 197.435 36.6238 197.435 36.4156V35.0898H195.782C195.065 35.0898 194.542 35.1941 194.214 35.4019C193.887 35.6101 193.723 35.9118 193.723 36.3065V36.6342C193.723 37.0294 193.866 37.3362 194.152 37.5544C194.438 37.773 194.825 37.882 195.314 37.882Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M201.382 38.7555V30.707H202.629V32.0024H202.692C202.889 31.5496 203.168 31.1892 203.526 30.9215C203.885 30.6538 204.366 30.52 204.969 30.52C205.801 30.52 206.459 30.7877 206.942 31.3231C207.426 31.8589 207.668 32.6153 207.668 33.5927V38.7555H206.42V33.8004C206.42 32.3516 205.812 31.6272 204.595 31.6272C204.345 31.6272 204.103 31.6587 203.869 31.7211C203.636 31.7838 203.425 31.8777 203.238 32.0028C203.051 32.1274 202.902 32.2865 202.793 32.4795C202.684 32.672 202.629 32.8986 202.629 33.1591V38.7555H201.382Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M213.236 38.9425C212.675 38.9425 212.17 38.8438 211.723 38.6464C211.276 38.449 210.899 38.1681 210.592 37.8041C210.286 37.4401 210.051 36.9982 209.89 36.4783C209.729 35.9585 209.649 35.3759 209.649 34.7314C209.649 34.0865 209.729 33.5043 209.89 32.9841C210.051 32.4643 210.286 32.0227 210.592 31.6583C210.899 31.2947 211.276 31.0138 211.723 30.816C212.17 30.6187 212.675 30.52 213.236 30.52C214.037 30.52 214.679 30.6942 215.162 31.0422C215.646 31.391 216.007 31.8457 216.246 32.4071L215.202 32.9374C215.056 32.5218 214.817 32.1994 214.484 31.9704C214.151 31.7418 213.735 31.6272 213.236 31.6272C212.862 31.6272 212.534 31.6871 212.253 31.8066C211.973 31.9265 211.739 32.0927 211.551 32.3056C211.364 32.519 211.224 32.7735 211.13 33.07C211.037 33.3665 210.99 33.6913 210.99 34.045V35.4175C210.99 36.1247 211.179 36.7045 211.559 37.1568C211.939 37.6091 212.498 37.8353 213.236 37.8353C214.245 37.8353 214.973 37.3674 215.42 36.4316L216.324 37.0398C216.065 37.6223 215.677 38.0846 215.162 38.4279C214.648 38.7711 214.006 38.9425 213.236 38.9425Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M221.113 38.9425C220.562 38.9425 220.065 38.8438 219.623 38.6464C219.181 38.449 218.802 38.1653 218.485 37.7961C218.167 37.4273 217.923 36.985 217.751 36.4704C217.58 35.9557 217.494 35.3759 217.494 34.7314C217.494 34.0969 217.58 33.5199 217.751 32.9997C217.923 32.4798 218.167 32.0355 218.485 31.6663C218.802 31.2971 219.181 31.0138 219.623 30.816C220.065 30.6187 220.562 30.52 221.113 30.52C221.653 30.52 222.137 30.6183 222.563 30.8152C222.989 31.0122 223.353 31.2843 223.655 31.6316C223.957 31.9784 224.188 32.3903 224.349 32.867C224.511 33.3437 224.591 33.8672 224.591 34.4369V35.0275H218.804V35.4019C218.804 35.7451 218.859 36.0648 218.968 36.3613C219.077 36.6578 219.233 36.9151 219.436 37.1333C219.639 37.3518 219.888 37.5232 220.185 37.6479C220.481 37.773 220.817 37.8353 221.191 37.8353C221.7 37.8353 222.145 37.7158 222.524 37.4765C222.904 37.2375 223.198 36.8943 223.406 36.4472L224.295 37.0905C224.035 37.6455 223.629 38.0926 223.078 38.4327C222.527 38.7727 221.872 38.9425 221.113 38.9425ZM221.113 31.5648C220.769 31.5648 220.458 31.6244 220.177 31.7434C219.896 31.8621 219.654 32.0299 219.452 32.2469C219.249 32.4639 219.09 32.72 218.976 33.0141C218.861 33.309 218.804 33.6318 218.804 33.9834V34.0917H223.25V33.9211C223.25 33.2079 223.055 32.6373 222.665 32.2081C222.275 31.7794 221.758 31.5648 221.113 31.5648Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M227.274 28.8198C227.004 28.8198 226.809 28.7546 226.689 28.6248C226.569 28.4949 226.51 28.3259 226.51 28.1177V27.9148C226.51 27.707 226.569 27.538 226.689 27.4081C226.809 27.2782 227.004 27.2131 227.274 27.2131C227.544 27.2131 227.739 27.2782 227.859 27.4081C227.978 27.538 228.038 27.707 228.038 27.9148V28.1177C228.038 28.3259 227.978 28.4949 227.859 28.6248C227.739 28.7546 227.544 28.8198 227.274 28.8198ZM226.65 30.7069H227.898V38.7555H226.65V30.7069Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M230.549 38.7555V30.7069H231.797V32.1889H231.875C232.021 31.8042 232.281 31.4609 232.655 31.1593C233.029 30.858 233.544 30.7069 234.199 30.7069H234.683V31.9548H233.95C233.274 31.9548 232.746 32.0823 232.367 32.3368C231.987 32.5917 231.797 32.9118 231.797 33.2962V38.7555H230.549V38.7555Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M242.123 38.7555C241.655 38.7555 241.32 38.6308 241.117 38.3811C240.914 38.1318 240.787 37.8197 240.735 37.4453H240.656C240.48 37.9444 240.189 38.3188 239.783 38.5685C239.377 38.8178 238.894 38.9425 238.333 38.9425C237.48 38.9425 236.817 38.7243 236.344 38.2876C235.87 37.8509 235.634 37.2579 235.634 36.5095C235.634 35.7503 235.912 35.1682 236.469 34.7626C237.025 34.357 237.891 34.1541 239.066 34.1541H240.656V33.3585C240.656 32.7867 240.501 32.35 240.189 32.0483C239.877 31.747 239.398 31.596 238.754 31.596C238.265 31.596 237.857 31.7051 237.529 31.9236C237.202 32.1418 236.929 32.4331 236.71 32.7971L235.962 32.0951C236.18 31.6583 236.529 31.2867 237.007 30.9799C237.485 30.6734 238.088 30.52 238.816 30.52C239.793 30.52 240.553 30.7585 241.093 31.236C241.634 31.7131 241.904 32.3772 241.904 33.2278V37.6479H242.825V38.7555H242.123V38.7555ZM238.535 37.882C238.847 37.882 239.133 37.8457 239.393 37.773C239.653 37.7002 239.877 37.596 240.064 37.4609C240.251 37.3258 240.397 37.17 240.501 36.993C240.605 36.8164 240.656 36.6238 240.656 36.4156V35.0898H239.003C238.286 35.0898 237.763 35.1941 237.436 35.4019C237.108 35.6101 236.944 35.9118 236.944 36.3065V36.6342C236.944 37.0294 237.087 37.3362 237.373 37.5544C237.659 37.773 238.046 37.882 238.535 37.882Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M247.021 38.9425C246.272 38.9425 245.645 38.7995 245.141 38.5138C244.637 38.2277 244.203 37.8457 243.839 37.3674L244.728 36.6498C245.04 37.0449 245.388 37.3494 245.773 37.5624C246.157 37.7754 246.605 37.882 247.114 37.882C247.645 37.882 248.058 37.7678 248.354 37.5388C248.651 37.3103 248.799 36.9878 248.799 36.5719C248.799 36.2598 248.697 35.9921 248.495 35.7683C248.292 35.545 247.925 35.3915 247.395 35.3084L246.755 35.2149C246.381 35.163 246.038 35.0823 245.726 34.9732C245.414 34.8637 245.141 34.7182 244.907 34.5364C244.673 34.3546 244.491 34.1285 244.361 33.8576C244.231 33.5875 244.166 33.265 244.166 32.8906C244.166 32.4954 244.239 32.1498 244.385 31.8533C244.53 31.5572 244.73 31.3103 244.985 31.1125C245.24 30.9151 245.544 30.7669 245.898 30.6678C246.251 30.5691 246.631 30.52 247.036 30.52C247.691 30.52 248.24 30.6342 248.682 30.8628C249.124 31.0917 249.511 31.409 249.844 31.8146L249.017 32.563C248.84 32.3136 248.58 32.0875 248.237 31.8845C247.894 31.6819 247.463 31.5804 246.943 31.5804C246.423 31.5804 246.03 31.6923 245.765 31.9157C245.5 32.1394 245.367 32.4439 245.367 32.8283C245.367 33.2235 245.5 33.5095 245.765 33.6862C246.03 33.8632 246.412 33.993 246.912 34.0761L247.535 34.1696C248.43 34.3051 249.064 34.5624 249.439 34.942C249.813 35.3216 250 35.8179 250 36.4316C250 37.2112 249.735 37.8249 249.204 38.272C248.674 38.7192 247.946 38.9425 247.021 38.9425Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M247.537 20.6362H225.74V22.3815H247.537V20.6362Z'
        fill={color[obj.logoTick]}
      />
      <path
        d='M173.01 22.3779H171.699V11.4904H176.036C176.992 11.4904 177.738 11.7557 178.274 12.286C178.809 12.8162 179.077 13.5806 179.077 14.5791C179.077 15.3798 178.892 16.0375 178.523 16.5522C178.154 17.0668 177.611 17.4077 176.893 17.5739L179.389 22.3779H177.907L175.552 17.6985H173.01V22.3779ZM176.036 16.5754C176.555 16.5754 176.958 16.4403 177.244 16.1698C177.53 15.8997 177.673 15.5149 177.673 15.0158V14.2047C177.673 13.7056 177.53 13.3209 177.244 13.0503C176.958 12.7802 176.555 12.6448 176.036 12.6448H173.01V16.5754H176.036V16.5754Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M184.598 22.5649C184.058 22.5649 183.563 22.4662 183.117 22.2688C182.669 22.0714 182.287 21.7877 181.97 21.4185C181.653 21.0497 181.406 20.6074 181.229 20.0928C181.052 19.5781 180.964 18.9983 180.964 18.3538C180.964 17.7193 181.052 17.1423 181.229 16.6221C181.406 16.1023 181.653 15.6579 181.97 15.2887C182.287 14.9195 182.669 14.6362 183.117 14.4384C183.563 14.2411 184.058 14.1424 184.598 14.1424C185.139 14.1424 185.63 14.2411 186.072 14.4384C186.514 14.6362 186.896 14.9195 187.219 15.2887C187.541 15.6579 187.79 16.1023 187.967 16.6221C188.144 17.1423 188.233 17.7193 188.233 18.3538C188.233 18.9983 188.144 19.5781 187.967 20.0928C187.79 20.6074 187.541 21.0497 187.219 21.4185C186.896 21.7877 186.514 22.0714 186.072 22.2688C185.63 22.4662 185.139 22.5649 184.598 22.5649ZM184.598 21.4577C185.274 21.4577 185.825 21.2499 186.252 20.8336C186.678 20.418 186.891 19.7835 186.891 18.9308V17.7764C186.891 16.9242 186.678 16.2896 186.252 15.8737C185.825 15.4577 185.274 15.2496 184.598 15.2496C183.922 15.2496 183.371 15.4577 182.945 15.8737C182.518 16.2896 182.305 16.9242 182.305 17.7764V18.9308C182.305 19.7835 182.518 20.418 182.945 20.8336C183.371 21.2499 183.922 21.4577 184.598 21.4577Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M191.836 22.3779C191.399 22.3779 191.069 22.256 190.846 22.0123C190.622 21.7685 190.51 21.4493 190.51 21.0549V15.4369H189.185V14.3293H189.933C190.203 14.3293 190.388 14.2746 190.487 14.1655C190.586 14.0564 190.635 13.8666 190.635 13.5961V12.0989H191.758V14.3293H193.521V15.4369H191.758V21.2703H193.396V22.3779H191.836Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M196.157 12.4422C195.886 12.4422 195.691 12.3771 195.572 12.2472C195.452 12.1173 195.393 11.9483 195.393 11.7401V11.5372C195.393 11.3294 195.452 11.1604 195.572 11.0305C195.691 10.9006 195.886 10.8355 196.157 10.8355C196.427 10.8355 196.622 10.9006 196.742 11.0305C196.861 11.1604 196.921 11.3294 196.921 11.5372V11.7401C196.921 11.9483 196.861 12.1173 196.742 12.2472C196.622 12.3771 196.427 12.4422 196.157 12.4422ZM195.533 14.3294H196.781V22.3779H195.533V14.3294Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M199.432 22.3779V14.3294H200.68V15.6248H200.743C200.94 15.1721 201.218 14.8116 201.577 14.5439C201.936 14.2762 202.417 14.1424 203.02 14.1424C203.852 14.1424 204.51 14.4101 204.993 14.9455C205.477 15.4813 205.718 16.2377 205.718 17.2151V22.3779H204.471V17.4228C204.471 15.974 203.862 15.2496 202.646 15.2496C202.396 15.2496 202.154 15.2811 201.92 15.3435C201.686 15.4062 201.476 15.5001 201.288 15.6252C201.101 15.7498 200.953 15.9089 200.844 16.1019C200.735 16.2944 200.68 16.521 200.68 16.7815V22.3779H199.432V22.3779Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M214.141 22.3779C213.673 22.3779 213.338 22.2532 213.135 22.0035C212.933 21.7542 212.805 21.4421 212.753 21.0677H212.675C212.498 21.5668 212.207 21.9412 211.801 22.1909C211.396 22.4402 210.912 22.5649 210.351 22.5649C209.498 22.5649 208.835 22.3467 208.362 21.91C207.889 21.4733 207.653 20.8803 207.653 20.1319C207.653 19.3727 207.931 18.7906 208.487 18.385C209.043 17.9794 209.909 17.7765 211.084 17.7765H212.675V16.9809C212.675 16.4091 212.519 15.9724 212.207 15.6707C211.895 15.3694 211.417 15.2184 210.772 15.2184C210.283 15.2184 209.875 15.3275 209.548 15.5461C209.22 15.7642 208.947 16.0555 208.729 16.4195L207.98 15.7175C208.198 15.2807 208.547 14.9091 209.025 14.6023C209.503 14.2958 210.106 14.1424 210.834 14.1424C211.812 14.1424 212.571 14.3809 213.112 14.8584C213.652 15.3355 213.923 15.9996 213.923 16.8503V21.2703H214.843V22.3779H214.141V22.3779ZM210.553 21.5044C210.866 21.5044 211.152 21.4681 211.411 21.3954C211.671 21.3226 211.895 21.2184 212.082 21.0833C212.269 20.9482 212.415 20.7924 212.519 20.6154C212.623 20.4388 212.675 20.2462 212.675 20.038V18.7123H211.022C210.304 18.7123 209.781 18.8165 209.454 19.0243C209.127 19.2325 208.963 19.5342 208.963 19.9289V20.2566C208.963 20.6518 209.105 20.9586 209.392 21.1768C209.678 21.3954 210.065 21.5044 210.553 21.5044Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M219.039 22.5649C218.29 22.5649 217.664 22.4219 217.159 22.1362C216.655 21.8501 216.221 21.4681 215.857 20.9898L216.746 20.2722C217.058 20.6674 217.406 20.9718 217.791 21.1848C218.175 21.3978 218.623 21.5045 219.132 21.5045C219.663 21.5045 220.076 21.3902 220.372 21.1612C220.669 20.9327 220.817 20.6102 220.817 20.1943C220.817 19.8822 220.715 19.6145 220.513 19.3907C220.31 19.1674 219.943 19.0139 219.413 18.9308L218.774 18.8373C218.399 18.7854 218.056 18.7047 217.744 18.5956C217.432 18.4861 217.159 18.3407 216.925 18.1588C216.691 17.977 216.509 17.7509 216.379 17.48C216.249 17.2099 216.184 16.8874 216.184 16.513C216.184 16.1178 216.257 15.7722 216.403 15.4757C216.548 15.1797 216.749 14.9327 217.003 14.7349C217.258 14.5375 217.562 14.3893 217.916 14.2902C218.269 14.1915 218.649 14.1424 219.054 14.1424C219.709 14.1424 220.258 14.2566 220.7 14.4852C221.142 14.7141 221.529 15.0314 221.862 15.437L221.036 16.1854C220.859 15.936 220.598 15.7099 220.256 15.5069C219.912 15.3043 219.481 15.2028 218.961 15.2028C218.441 15.2028 218.048 15.3147 217.783 15.5381C217.518 15.7618 217.385 16.0663 217.385 16.4507C217.385 16.8459 217.518 17.132 217.783 17.3086C218.048 17.4856 218.43 17.6154 218.93 17.6985L219.554 17.792C220.448 17.9275 221.082 18.1848 221.457 18.5644C221.831 18.944 222.018 19.4403 222.018 20.054C222.018 20.8336 221.753 21.4473 221.223 21.8944C220.692 22.3416 219.964 22.5649 219.039 22.5649Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M157.165 34.355V21.5765C157.165 16.3397 154.389 12.0975 146.481 12.0975C141.977 12.0975 138.206 13.9305 135.797 16.0778V16.3397C137.683 15.7108 139.987 15.2922 142.291 15.2922C145.905 15.2922 148.261 16.4964 148.261 20.5814V24.1951C148.26 24.1951 148.258 24.1951 148.257 24.1951V24.7189C148.258 24.7189 148.26 24.7189 148.261 24.7189V34.1451C147.424 34.6692 146.586 34.9311 145.748 34.9311C143.967 34.9311 142.815 33.0457 142.815 30.4271C142.815 28.4255 143.654 25.7135 146.536 24.9398V24.6334V24.2324C137.977 24.6047 133.912 27.7949 133.912 32.4172C133.912 36.5023 136.95 39.33 141.139 39.33C144.125 39.33 146.481 38.073 148.261 34.6692C148.89 38.0207 151.404 39.33 153.813 39.33C155.488 39.33 157.479 38.8063 159.102 37.445V37.1831L157.165 34.355Z'
        fill={color[obj.logoColor]}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M113.905 25.5082H113.906V35.8734L115.792 38.5444V38.8063H103.066V38.5444L105.003 35.8734V5.44621L103.066 2.40902V2.14716H113.906V10.51H113.905V25.5082ZM129.617 19.2723V35.8736L131.503 38.5446V38.8064H118.777V38.5446L120.714 35.8736V19.2723C120.714 16.6541 119.667 15.8681 117.73 15.8681C117.071 15.8681 116.289 15.893 115.886 16.0011C115.864 15.9433 115.844 15.8892 115.826 15.8373C115.765 15.6717 115.713 15.5278 115.63 15.3534C116.998 13.5535 120.31 12.0977 122.443 12.0977C126.946 12.0977 129.617 14.7163 129.617 19.2723Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M87.616 38.5447L89.5541 35.8737V5.44652L87.616 2.40932V2.14746H98.4567V35.8737L100.342 38.5447V38.8066H87.616V38.5447Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M73.3188 4.76549C73.3188 2.09449 75.5181 0 78.2938 0C81.0168 0 83.2688 2.09449 83.2688 4.76549C83.2688 7.38411 81.0168 9.53138 78.2938 9.53138C75.5181 9.53139 73.3188 7.38411 73.3188 4.76549ZM72.1663 38.5445L74.1044 35.8735V18.0677L72.1663 15.0301V14.7682L82.7451 12.0976H83.007V35.8735L84.8923 38.5445V38.8064H72.1663V38.5445Z'
        fill={color[obj.logoColor]}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M67.5573 12.0975V34.355L69.4427 37.1831V37.445L58.9166 39.33H58.6548V35.5072L58.6499 35.5109V34.9847L58.6548 34.9835V18.068L56.7166 15.0304V14.7681L67.2954 12.0975H67.5573ZM54.7792 35.5076C55.4518 35.5076 56.5558 35.4204 57.1941 35.2261V35.5825C55.0692 37.8946 52.2047 39.3308 50.0656 39.3308C45.5616 39.3308 42.9434 36.7121 42.9434 32.1034V18.0684L41.0056 15.0307V14.7689L51.5844 12.0983H51.8463V32.1034C51.8463 34.7744 52.841 35.5076 54.7792 35.5076Z'
        fill={color[obj.logoColor]}
      />
      <path
        d='M14.988 42.1696C26.7849 40.1226 36.9157 36.5069 36.9157 19.8749C36.9157 7.38571 29.604 0.711487 18.7616 0.711487C8.03062 0.711487 0 7.31042 0 19.8749C0 32.1072 6.2356 37.0564 15.2613 38.7102L17.0824 37.9393C12.4475 36.4733 10.1136 29.2926 10.1136 19.8749C10.1136 9.29117 11.9724 1.72989 18.7367 1.72989C25.2296 1.72989 26.802 9.29117 26.802 19.8749C26.802 35.8301 17.7682 39.9062 9.60423 42.1696L22.5431 50.271L35.5229 46.4969C35.5421 46.3357 35.5434 45.8063 35.563 45.6455L14.988 42.1696Z'
        fill={color[obj.logoColor]}
      />
    </Svg>
  ) : (
    <SvgSmall
      top={50}
      left={24}
      width='32'
      height='42'
      viewBox='0 0 32 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.7754 28.4698C12.7754 28.4698 4.67565 26.1699 4.67565 16.1706C4.67565 6.17135 10.607 2.05776 15.7947 2.05776C20.9824 2.05776 26.9134 5.50683 26.9134 15.6009C26.9134 26.7392 18.9896 30.8369 12.3464 32.9728C20.8941 37.5193 25.6804 39.9394 25.6804 39.9394L12.7754 28.4698ZM30.6483 15.6255C30.6483 7.54795 24.0116 1 15.8242 1C7.63678 1 1 7.54795 1 15.6255C1 22.3408 5.59296 28.4747 12.7675 28.4747L12.7754 28.4698C10.1202 26.3758 8.25802 21.5939 8.25802 15.6255C8.25802 7.54795 10.6636 3.07019 15.8242 3.07019C20.7332 3.07019 23.3903 7.54795 23.3903 15.6255C23.3903 27.8026 14.8434 31.682 8.78521 32.6408L20.5961 41.1354C20.6845 41.1664 20.7498 41.189 20.8382 41.22C20.8382 41.22 22.3118 40.8094 30.4282 38.6655L16.3325 32.878C23.0701 30.7421 30.6483 26.8688 30.6483 15.6255Z'
        stroke={color[obj.strokeColor]}
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
    </SvgSmall>
  )
}

const Svg = styled.svg`
  width: ${pm(200)};
  height: ${pm(40.22)};
  position: absolute;
  top: ${props => pm(props.top)};
  left: ${props => pm(props.left)};
`

const SvgSmall = styled.svg`
  width: ${pm(29.65)};
  height: ${pm(40.22)};
  position: absolute;
  top: ${props => pm(props.top)};
  left: ${props => pm(props.left)};
`
