import React from 'react'
import styled, { css } from 'styled-components'
import { px, color } from '../styles'
import { motion } from 'framer-motion'

const Title = props =>
  props.motion ? <STitleMotion {...props} /> : <STitle {...props} />

const Label = props => <SLabel {...props} htmlFor={props.children} />

const Paragraph = props =>
  props.motion ? <SParagraphMotion {...props} /> : <SParagraph {...props} />

const TitleMixin = css`
  width: ${props => px(props.width)};
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: ${props => (props.weight ? props.weight : 300)};
  font-size: ${props => (props.fontSize ? px(props.fontSize) : px(44))};
  line-height: ${props => (props.lineHeight ? px(props.lineHeight) : px(57))};
  position: ${props => (props.relative ? 'relative' : 'absolute')};
  top: ${props => px(props.top)};
  left: ${props => px(props.left)};
  color: ${props => (props.color ? color[props.color] : color.black)};
  ${props => props.textAlign && `text-align: ${props.textAlign}`};
`

const STitle = styled.h1`
  ${TitleMixin};
`

const SLabel = styled.label`
  ${TitleMixin};
`

const STitleMotion = styled(motion.h1)`
  ${TitleMixin};
`

const SParagraphMixin = css`
  width: ${props => px(props.width)};
  position: ${props => (props.relative ? 'relative' : 'absolute')};

  top: ${props => px(props.top)};
  left: ${props => (props.left ? px(props.left) : 0)};
  font-family: ${props =>
    props.sans ? `'IBM Plex Sans', sans-serif;` : `'IBM Plex Serif', serif;`};
  font-style: normal;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-size: ${props => (props.fontSize ? px(props.fontSize) : px(20))};
  line-height: ${props => (props.lineHeight ? px(props.lineHeight) : px(26))};
  ${props => props.textAlign && `text-align: ${props.textAlign}`};
  color: ${props => (props.color ? color[props.color] : color.black)};
  opacity: ${props => props.opacity};
`

const SParagraph = styled.h2`
  ${SParagraphMixin};
`

const SParagraphMotion = styled(motion.h2)`
  ${SParagraphMixin};
`

const A = styled.a.attrs(props => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  href: `https://www.${props.href}.com/${props.route ? props.route : ''}`,
}))``

export { Title, Label, Paragraph, A }
