import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { pm } from '../../styles'
import { Page1, Page2, Page3, Page4, Page5, Page6, Page7 } from './pages'
import { Helmet } from 'react-helmet'
import Nav from './components/Navbar'

const scrollToPosition = position =>
  window.scrollTo({ top: position, behavior: 'smooth' })

const between = (min, max) =>
  (window.scrollY - min) * (window.scrollY - max) <= 0

export default () => {
  const ref2 = useRef(null),
    ref3 = useRef(null),
    ref4 = useRef(null),
    ref5 = useRef(null),
    ref6 = useRef(null),
    ref7 = useRef(null)

  const [page, setPage] = useState('/')
  const [position, setPosition] = useState([0])

  useEffect(() => {
    if (ref2.current) setPosition(prev => [...prev, ref2.current.offsetTop])
    if (ref3.current) setPosition(prev => [...prev, ref3.current.offsetTop])
    if (ref4.current) setPosition(prev => [...prev, ref4.current.offsetTop])
    if (ref5.current) setPosition(prev => [...prev, ref5.current.offsetTop])
    if (ref6.current) setPosition(prev => [...prev, ref6.current.offsetTop])
    if (ref7.current) setPosition(prev => [...prev, ref7.current.offsetTop])
  }, [ref2, ref3, ref4, ref5, ref6, ref7])

  useEffect(() => {
    const handleScroll = () => {
      between(position[0], position[1]) && setPage('/')
      between(position[1], position[2]) && setPage('/o-que-somos')
      between(position[2], position[3]) && setPage('/reducao-de-custos-e-tempo')
      between(position[3], position[4]) && setPage('/foco-no-core-business')
      between(position[4], position[5]) && setPage('/organizacao-e-execucao')
      between(position[5], position[6]) &&
        setPage('/mais-capacidade-e-eficiencia')

      window.scrollY >= position[6] && setPage('/fale-com-a-gente')
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [position])

  useEffect(() => {
    const preventZoom = e => e.preventDefault()
    window.addEventListener('gesturestart', preventZoom)
    return () => window.removeEventListener('gesturestart', preventZoom)
  })

  return (
    <App>
      <Helmet>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
        />
      </Helmet>
      <Nav onClick={p => scrollToPosition(position[p])} pathname={page} />

      <Page1 onClick={p => scrollToPosition(position[p])} />
      <Page2 ref={ref2} />
      <Page3 ref={ref3} />
      <Page4 ref={ref4} />
      <Page5 ref={ref5} />
      <Page6 ref={ref6} />
      <Page7 ref={ref7} />
    </App>
  )
}

const App = styled.div`
  width: ${pm(375)};
  position: relative;
`
