import React from 'react'
import styled from 'styled-components'
import { px } from '../styles'

export default props => (
  <Icon {...props} src={require(`../assets/${props.name}.svg`)} alt='Icon' />
)

const Icon = styled.img`
  width: ${props => (props.width ? px(props.width) : px(86))};
  height: ${props => (props.height ? px(props.height) : px(86))};
  position: absolute;
  top: ${props => px(props.top)};
  left: ${props => px(props.left)};
`
