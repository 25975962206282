import React, { useState } from 'react'
import styled from 'styled-components'
import { px, direction, alignment } from '../styles'
import { Left, Right } from '../components/pages/Pages'
import { Title, Paragraph, A } from '../components/Texts'
import { Rectangle, Blanc } from '../components'
import baloon from '../assets/seven1.svg'
import icon1 from '../assets/seven2.svg'
import icon2 from '../assets/seven3.svg'
import icon3 from '../assets/seven4.svg'
import Form from '../components/form2'
import Modal from '../components/Modal'
import { Helmet } from 'react-helmet'

export default () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Helmet>
        <title>Fale com a gente</title>
      </Helmet>
      <Left>
        <Title top={159} left={60} color='salmonLight'>
          Fale <br /> com a gente
        </Title>

        <Paragraph top={302} left={60} color='salmonLight' lineHeight={28}>
          Ficou interessado? <br /> Deixe seus dados e a gente entra em contato{' '}
          <br /> rapidinho com você.
        </Paragraph>

        <Form setOpen={setOpen} open={open} />

        <Paragraph
          top={778}
          left={202}
          color='white'
          fontSize={12}
          lineHeight={16}
          textAlign='center'
          opacity={0.5}
        >
          Brasília, DF, 70610-410 Sala 107 - Ed Parque Brasília, <br /> St. de
          Indústrias Gráficas, Quadra 3 <br /> Qrf Gestao Administrativa LTDA
        </Paragraph>

        <IconBox>
          <A href='instagram' route='quilharotinas'>
            <Icon src={icon1} alt='Icon-1' />
          </A>
          <A
            href='facebook'
            route='Quilha-Rotinas-Financeiras-100389601419037/about/'
          >
            <Icon src={icon2} alt='Icon-2' />
          </A>
          <A href='medium'>
            <Icon src={icon3} alt='Icon-3' />
          </A>
        </IconBox>
      </Left>

      <Right>
        <Blanc
          width={431}
          height={540}
          top={245}
          left={130}
          color='greenDark'
        />
        <Rectangle
          top={302}
          left={121}
          width={360}
          height={424}
          opacity={0.2}
        />
        <Blanc width={397} height={274} top={366} left={15} color='greenDark' />
        <Image src={baloon} alt='baloon' />
      </Right>
      {open && <Modal open={open} setOpen={() => setOpen(!open)} />}
    </>
  )
}

const Image = styled.img`
  width: ${px(340.65)};
  height: ${px(250)};
  position: absolute;
  top: ${px(381)};
  left: ${px(57)};
`

const IconBox = styled.div`
  width: ${px(126)};
  height: ${px(21)};
  position: absolute;
  top: ${px(831)};
  left: ${px(292)};
  ${direction()};
  ${alignment({ main: 'space-between' })};
`

const Icon = styled.img`
  width: ${px(21)};
  height: ${px(21)};
  cursor: pointer;
`
