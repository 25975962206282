import React, { useState, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
// import { initGA } from './index'
import MobileSideScreen from './pages/Mobile/pages/MobileSideScreen'

import {
  Page1,
  Page2,
  Page3,
  Page4,
  Page5,
  Page6,
  Page7,
  Page404,
  Mobile,
} from './pages'
import { AnimatePresence } from 'framer-motion'
import AppContainer from './components/pages/AppContainer'
import { SliderContext } from './utils/sliderContext'
import Nav from './components/navbar'
import Sidebar from './components/Sidebar'
import { ModalContext } from './utils/modalContext'
import useGoogleAnalytics from './useGoogleAnalytics'

export default () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  const location = useLocation()

  const [[page, direction], setPage] = useState([0, 0])
  const [modalOpen, setModalOpen] = useState(false)

  const paginate = newDirection => setPage([page + newDirection, newDirection])

  const obj = { direction, paginate, pathname: location.pathname }

  // useEffect(() => initGA(), []) // ANALYTICS

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (width <= 414) return <Mobile />
  else if (width > 414 && height <= 414)
    return (
      <MobileSideScreen />
      // <div>
      //   PARA UMA MELHOR VISUALIZAÇÃO, ROTACIONE O CELULAR PARA A POSIÇÃO
      //   VERTICAL
      // </div>
    )
  else {
    return (
      <SliderContext.Provider value={obj}>
        <Nav />

        <ModalContext.Provider value={{ modalOpen, setModalOpen }}>
          <AppContainer>
            <AnimatePresence initial={false} custom={direction}>
              <Routes {...{ location, useGoogleAnalytics }} />
            </AnimatePresence>
          </AppContainer>
        </ModalContext.Provider>

        <Sidebar />
      </SliderContext.Provider>
    )
  }
}

const Routes = ({ useGoogleAnalytics, location }) => {
  useGoogleAnalytics()
 
  return (
    <Switch location={location} key={location.pathname}>
      <Route exact path='/' component={Page1} />
      <Route path='/o-que-somos' component={Page2} />
      <Route path='/reducao-de-custos-e-tempo' component={Page3} />
      <Route path='/foco-no-core-business' component={Page4} />
      <Route path='/organizacao-e-execucao' component={Page5} />
      <Route path='/mais-capacidade-e-eficiencia' component={Page6} />
      <Route path='/fale-com-a-gente' component={Page7} />
      <Route render={() => <Page404 />} />
    </Switch>
  )
}
