import React, { useEffect } from 'react'
import styled from 'styled-components'
import { color, pm, direction, alignment } from '../../../styles'
import { Title, Paragraph } from '../components/Texts'
import { SvgLine } from '../components'
import qGigante from '../../../assets/q.svg'

export default ({ open, setOpen }) => {
  useEffect(() => {
    if (open) document.body.style.overflow = 'hidden'
    return () => (document.body.style.overflow = 'unset')
  }, [open])

  return (
    <Modal>
      <SvgLine x1={380} x2={-28} stroke='white' />

      <Panel>
        <Title color='salmonLight' fontSize={34} relative lineHeight={44}>
          Legal, deu tudo certo!
        </Title>
        <Q src={qGigante} alt='Logo-big' />
        <Paragraph
          color='salmonLight'
          nonPos
          fontSize={26}
          lineHeight={34}
          left={24}
          textAlign='center'
        >
          Seus dados para contato foram enviados <br /> e logo a gente vai falar
          com você.
        </Paragraph>
        <Button onClick={() => setOpen(!open)}>Ok, Entendi</Button>
      </Panel>
    </Modal>
  )
}

const Panel = styled.div`
  height: ${pm(534)};
  width: ${pm(330)};

  ${direction('column')};
  ${alignment({ main: 'space-between' })};

  background-color: ${color.orangeDark};

  position: relative;
`

const Modal = styled.div`
  z-index: 2;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  background-color: ${color.orangeDark};

  ${direction()};
  ${alignment()};
`

const Q = styled.img`
  width: ${pm(134.09)};
  height: ${pm(179.47)};
`

const Button = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;

  display: inline-block;
  background: ${color.orangeDark};
  color: white;
  font-size: ${pm(16)};
  font-weight: 600;

  width: ${pm(268)};
  height: ${pm(60)};

  ${direction()};
  ${alignment()};

  border: ${pm(1)} solid rgba(255, 255, 255, 0.6);
`
