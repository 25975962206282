import styled from 'styled-components'
import { pm, color } from '../../../styles'

export default styled.div`
  width: ${props => (props.width ? pm(props.width) : pm(222))};
  height: ${props => (props.height ? pm(props.height) : pm(213))};

  ${props =>
    `border: ${props.border ? pm(props.border) : pm(6.1588)} solid ${
      props.color ? color[props.color] : color.greenLight
    };`};

  position: absolute;
  opacity: ${props => props.opacity};
  top: ${props => pm(props.top)};
  left: ${props => pm(props.left)};
  background-color: ${props => color[props.backgroundColor]};
`
