import React from 'react'
import { Left, Right } from '../components/pages/Pages'
import styled from 'styled-components'
import { Rectangle, Blanc } from '../components'
import { px, color } from '../styles'
import qGigante from '../assets/q.svg'
import { Helmet } from 'react-helmet'

import { Paragraph } from '../components/Texts'

export default () => (
  <>
    <Helmet>
      <title>O que somos?</title>
    </Helmet>
    <Left>
      <Blanc
        width={420.25}
        height={629.25}
        top={233}
        left={192}
        color='orangeDark'
      />
      <Rectangle
        width={392}
        height={562}
        color='orangeMedium'
        top={267}
        left={192}
      />
      <Q src={qGigante} alt='Logo-large' />
    </Left>

    <Right>
      <Box />
      <Title left={78} lineHeight={52} fontSize={40} top={127}>
        O que somos?
      </Title>

      <Paragraph lineHeight={28} fontSize={18} left={78} width={550} top={223}>
        Uma empresa especializada em rotinas financeiras que surge do ideal que
        o departamento financeiro não deve concorrer com o core business dos
        nossos clientes.
      </Paragraph>

      <Paragraph lineHeight={28} fontSize={18} left={78} width={550} top={333}>
        Acreditamos que o processo financeiro não precisa ser desgastante e que,
        quando realizado de forma mais assertiva, além de gerar economia de
        tempo gera também tranquilidade às empresas.
      </Paragraph>

      <Title left={78} lineHeight={52} fontSize={40} top={567}>
        Nossa solução
      </Title>

      <Paragraph
        lineHeight={28}
        fontSize={18}
        left={78}
        height={109}
        width={550}
        top={663}
      >
        Uma plataforma de automatização de rotinas financeiras criada por
        especialistas, que por meio de tecnologia ajuda empreendedores a terem
        acesso a um setor financeiro estruturado de forma simples e rápida.
      </Paragraph>
    </Right>
  </>
)

const Q = styled.img`
  width: ${px(393)};
  height: ${px(526)};

  position: absolute;
  top: ${px(223)};
  left: ${px(104)};
`

const Box = styled.div`
  height: ${px(383)};

  background-color: ${color.salmonLight};

  position: absolute;
  top: ${px(158)};
  left: ${px(27)};

  width: calc(50vw - ${px(27)});
`

const Title = styled.h1`
  width: ${props => px(props.width)};
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: ${props => (props.weight ? props.weight : 300)};
  font-size: ${props => (props.fontSize ? px(props.fontSize) : px(44))};
  line-height: ${props => (props.lineHeight ? px(props.lineHeight) : px(57))};
  position: ${props => (props.relative ? 'relative' : 'absolute')};
  top: ${props => px(props.top)};
  left: ${props => px(props.left)};
  color: ${props => (props.color ? color[props.color] : color.black)};
  ${props => props.textAlign && `text-align: ${props.textAlign}`};
`
