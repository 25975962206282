import React from 'react'
import { Paragraph } from '../../Texts'

export default ({ activeStep }) => (
  <Paragraph
    motion
    sans
    fontSize={14}
    color='salmonLight'
    top={116}
    lineHeight={20}
  >
    {activeStep} de 5 passos
  </Paragraph>
)
