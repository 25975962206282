import React from 'react'
import styled from 'styled-components'
import { color } from '../../../../styles'

export default ({ open, children }) => (
  <Container>{open && <Box>{children}</Box>}</Container>
)

const Box = styled.div`
  padding-top: 16vw;
`

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: ${color.greenDark};
  opacity: 1;
  color: ${color.white};
  transition: height 0.3s ease;
  box-shadow: 0 3.73vw 8vw 0 rgba(0, 0, 0, 0.2); // 0 14px 30px 0
  z-index: 2;

  opacity: 0.93;
`
