import React, { useContext } from 'react'
import Input from '../components/Input'
import { FormContext } from '../../../utils/formContext'

export default () => {
  const form = useContext(FormContext)

  // const validateEmail = email => {
  //   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //   return re.test(String(email).toLowerCase())
  // }

  // const handleEmail = e => {
  //   const res = validateEmail(e.target.value)
  //   console.log(res)
  //   form.setFormOk(res)
  //   form.setNome(e.target.value)
  // }

  return (
    <Input
      {...form.variantNames}
      transition={{ duration: 1 }}
      variants={form.variants}
      custom={form.direction}
      // onChange={e => handleEmail(e)}
      onChange={e => form.setNome(e.target.value)}
      value={form.nome}
      label='nome'
    >
      Não precisa ser nome completo
    </Input>
  )
}
