import React from 'react'
import styled from 'styled-components'
import { pm, color, direction, alignment } from '../../../styles'
import { Rectangle, Blanc } from '../components'
import Line from '../components/SvgLine'
import { Helmet } from 'react-helmet'

export default ({ onClick }) => (
  <Page>
    <Helmet>
      <title>Bem Vindo.</title>
      <meta
        name='description'
        content='Cuidamos do seu financeiro para que ele rode no piloto automático.'
      />
      <link rel='canonical' href={window.location.href} />
    </Helmet>
    <Line x1={355} x2={-50} stroke='orangeDark' />
    <Blanc top={170} height={370} color='greenDark' />
    <Rectangle top={188} left={36} opacity={0.2} />
    <Title>
      Cuidamos do seu financeiro para que ele rode no piloto automático.
    </Title>
    <Button onClick={() => onClick(6)}>Fale com a gente</Button>
  </Page>
)

const Page = styled.div`
  width: 100%;
  height: ${pm(812)};

  position: relative;

  background-color: ${color.greenDark};
`

const Title = styled.h1`
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: ${pm(30.1781)};
  line-height: ${pm(39)};

  color: ${color.greenLight};

  text-shadow: ${pm(0)} ${pm(4)} ${pm(4)} rgba(0, 0, 0, 0.25);

  width: ${pm(276)};

  position: absolute;
  top: ${pm(209)};
  left: ${pm(62)};
`

const Button = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;

  display: inline-block;
  background: ${color.orangeDark};
  color: white;
  font-size: ${pm(16)};

  width: ${pm(297)};
  height: ${pm(60)};

  ${direction()};
  ${alignment()}

  transition: all 0.3s ease-in-out;

  text-decoration: none;

  position: absolute;
  top: ${pm(435)};
  left: ${pm(38)};

  border: ${pm(1)} solid rgba(255, 255, 255, 0.6);
`
