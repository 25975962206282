import React from 'react'
import styled from 'styled-components'
import { color, px } from '../../styles'
import mLogo from '../../assets/logo.svg'

export default ({ open, children }) => (
  <Container open={open}>
    {open && (
      <>
        <Logo src={mLogo} alt='Logo' />
        {children}
      </>
    )}
  </Container>
)

const Logo = styled.img`
  width: ${px(250)};
  height: ${px(50.27)};

  position: absolute;
  top: ${px(50)};
  left: calc(50% - ${px(125)});
`

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.open && `100vh`};
  display: flex;
  flex-direction: column;
  background: ${color.greenDark};
  opacity: 1;
  color: ${color.white};
  transition: height 0.3s ease;
  box-shadow: 0 3.73vw 8vw 0 rgba(0, 0, 0, 0.2); // 0 14px 30px 0
  z-index: 2;

  opacity: 0.93;
`
