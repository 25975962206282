import React from 'react'
import styled from 'styled-components'
import { pm, color, direction, alignment } from '../../../../styles'

export default ({ onClick, obj }) => (
  <>
    {!obj.button && (
      <SButton
        onClick={() => onClick(6)}
        backgroundColor={obj.navBackground}
        color={obj.strokeColor}
      >
        Fale com a gente
      </SButton>
    )}
  </>
)

const SButton = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;

  display: inline-block;
  background: ${props => color[props.backgroundColor]};
  color: ${props => color[props.color]};
  font-size: ${pm(14)};
  font-weight: bold;

  width: ${pm(211)};
  height: ${pm(36)};

  ${direction()};
  ${alignment()}

  text-decoration: none;

  position: absolute;
  top: ${pm(52)};
  left: ${pm(82)};

  border: ${pm(2)} solid ${props => color[props.color]};
`
