import React, { useContext } from 'react'
import Input from '../components/Input'
import { FormContext } from '../../../../../utils/formContext'

export default () => {
  const form = useContext(FormContext)

  return (
    <Input
      {...form.variantNames}
      transition={{ duration: 1 }}
      variants={form.variants}
      custom={form.direction}
      onChange={e => form.setEmail(e.target.value)}
      value={form.email}
      label='E-mail'
    >
      Digite seu e-mail
    </Input>
  )
}
