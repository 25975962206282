import React from 'react'
import styled from 'styled-components'
import { pm } from '../../../styles'

export default props => (
  <Icon
    {...props}
    src={require(`../../../assets/${props.name}.svg`)}
    alt='assets-icon'
  />
)

const Icon = styled.img`
  width: ${props => (props.width ? pm(props.width) : pm(75))};
  height: ${props => (props.height ? pm(props.height) : pm(75))};
  position: absolute;
  top: ${props => pm(props.top)};
  left: ${props => pm(props.left)};
`
