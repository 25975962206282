import React from 'react'
import styled from 'styled-components'
import { color } from '../styles'

export default ({ stroke }) => (
  <Svg name='Line'>
    <line
      x1='64%'
      x2='32%'
      y1='0'
      y2='100%'
      stroke={color[stroke]}
      strokeWidth='2'
    />
  </Svg>
)

const Svg = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;

  /* border: solid ${color.darkyellow}; */
`
