export default [
  {
    path: '/',
    logoColor: 'white',
    logoTick: 'orangeDark',
    activeColor: 'white',

    button: 'no',
    logoSize: 'big',
    strokeColor: 'white',
    navBackground: 'greenDark',
  },
  {
    path: '/o-que-somos',
    logoColor: 'white',
    logoTick: 'white',
    activeColor: 'orangeDark',

    strokeColor: 'greenDark',
    navBackground: 'white',
  },
  {
    path: '/reducao-de-custos-e-tempo',
    logoColor: 'greenDark',
    logoTick: 'orangeDark',
    hoverColor: 'orangeDark',
    activeColor: 'greenDark',

    strokeColor: 'greenDark',
    navBackground: 'salmonLight',
  },
  {
    path: '/foco-no-core-business',
    logoColor: 'greenDark',
    logoTick: 'orangeDark',
    hoverColor: 'greenDark',
    activeColor: 'orangeDark',

    strokeColor: 'white',
    navBackground: 'greenDark',
  },
  {
    path: '/organizacao-e-execucao',
    logoColor: 'greenDark',
    logoTick: 'orangeDark',
    activeColor: 'orangeDark',
    sidebarBackgroundColor: 'salmonLight',

    strokeColor: 'greenDark',
    navBackground: 'salmonLight',
  },
  {
    path: '/mais-capacidade-e-eficiencia',
    logoColor: 'white',
    logoTick: 'orangeDark',
    hoverColor: 'orangeDark',
    activeColor: 'greenDark',

    strokeColor: 'white',
    navBackground: 'orangeDark',
  },
  {
    path: '/fale-com-a-gente',
    logoColor: 'white',
    logoTick: 'orangeDark',
    activeColor: 'orangeDark',

    button: 'no',
    logoSize: 'big',
    navBackground: 'greenDark',
    strokeColor: 'white',
  },
]
