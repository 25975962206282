import React from 'react'
import styled from 'styled-components'
import { color } from '../styles'
import path from '../utils/paths'
import { useLocation } from 'react-router-dom'

export default ({ side }) => {
  const { pathname } = useLocation()
  const obj = path.find(p => p.path === pathname)

  const width = window.innerWidth / 2

  return (
    <Svg line>
      <line
        x1={side === 'right' ? obj.X1R : width - obj.X1L.split('px')[0]}
        x2={side === 'right' ? obj.X2R : width - obj.X2L.split('px')[0]}
        y1='0'
        y2='100%'
        stroke={
          side === 'right' ? color[obj.colorLineR] : color[obj.colorLineL]
        }
        strokeWidth='2'
      />
    </Svg>
  )
}

const Svg = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;

  /* border: solid ${color.darkyellow}; */
`
