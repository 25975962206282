import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { pm, color } from '../../../styles'
import { Icon, Rectangle, Cellphone, Blanc } from '../components'
import { Title, Paragraph } from '../components/Texts'
import Line from '../components/SvgLine'
import { Helmet } from 'react-helmet'

export default forwardRef((_, ref) => (
  <Page ref={ref}>
    <Helmet>
      <title>Foco no Core Business.</title>
      <meta
        name='description'
        content='Tenha todas as informações financeiras da sua empresa na palma da sua mão de forma organizada e fácil.'
      />
      <link rel='canonical' href={window.location.href} />
    </Helmet>
    <Line x1={375} x2={-270} stroke='greenLight' />
    <Blanc top={205} height={530} color='salmonLight' />
    <Title top={135} left={24} color='orangeDark'>
      Organização <br /> e Execução
    </Title>
    <Paragraph top={268} left={24} lineHeight={30} width={295}>
      Tenha todas as informações financeiras da sua empresa na palma da sua mão
      de forma organizada e fácil. Encontre onde está aquele comprovante
      rapidamente ou se aquela conta foi realmente paga. Tenha relatórios
      detalhados sobre a movimentação bancária da sua empresa sem preocupação e
      informações precisas para realização de análises sobre o seu negócio.
    </Paragraph>
    <Icon top={670} left={24} name='five1' />
    <Icon top={670} left={125.83} name='five2' />
    <Icon top={670} left={250.83} name='five3' />
    <Blanc top={800} color='white' width={375} height={509}>
      <Line x1={280} x2={30} stroke='orangeDark' />
      <Blanc top={280} height={200} color='white' />
    </Blanc>
    <Rectangle
      top={841.6}
      left={47.82}
      width={288.18}
      height={413.15}
      color='salmonLight'
    />
    <Cellphone top={760} left={39} name='page5' />
  </Page>
))

const Page = styled.div`
  width: 100%;
  height: ${pm(1299)};

  position: relative;

  background-color: ${color.salmonLight};
`
