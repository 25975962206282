import React from 'react'
import styled, { css } from 'styled-components'
import { pm, color } from '../../../../../styles'
import { motion } from 'framer-motion'

export default ({ children, ...props }) => (
  <Input
    {...props}
    placeholder={children}
    value={props.value}
    id={props.label}
  />
)

const Mixin = css`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: normal;
  font-size: ${pm(14)};
  align-items: center;
  color: ${color.greenDark};
  text-size-adjust: none;
`

const Input = styled(motion.input)`
  position: absolute;
  top: ${pm(32)};
  width: 100%;
  height: ${pm(56)};
  opacity: 0.4;
  box-sizing: border-box;
  padding-left: ${pm(20)};

  &::placeholder {
    ${Mixin};
    line-height: ${pm(13)};
  }
`
