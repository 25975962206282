const valName = input => {
  const regex = /^[a-z ,.'-]+$/i
  if (input.match(regex)) return true
  else {
    alert('Por favor coloque um nome real.')
    return false
  }
}
const valEmail = input => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (regex.test(String(input).toLowerCase())) return true
  else {
    alert('Por favor coloque um email real.')
    return false
  }
}
const valNumber = input => {
  const regex = /^[0-9][A-Za-z0-9 -]*$/
  if (input.match(regex)) return true
  else {
    alert('A quantidade precisa ser um número.')
    return false
  }
}
const valTel = input => {
  const regex = /(\([1-9][0-9]\)?|[1-9][0-9])\s?([9]{1})?([0-9]{4})-?([0-9]{4})$/
  if (input.match(regex)) return true
  else {
    alert('Por favor coloque um telefone real. (Com DDD na frente)')
    return false
  }
}

export { valName, valNumber, valTel, valEmail }
