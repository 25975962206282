import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { color, direction, alignment } from '../../styles'
import useBackgroundColor from '../../utils/useBackgroundColor'
import { SliderContext } from '../../utils/sliderContext'
import Line from '../../components/SvgLine'

const variant = {
  initial: custom => ({
    y: custom > 0 ? '-100vh' : '100vh',
  }),
  animate: {
    y: 0,
  },
  exit: custom => ({
    y: custom > 0 ? '100vh' : '-100vh',
  }),
}

const variant2 = {
  initial: custom => ({
    y: custom > 0 ? '100vh' : '-100vh',
  }),
  animate: {
    y: 0,
  },
  exit: custom => ({
    y: custom > 0 ? '-100vh' : '100vh',
  }),
}

const Left = ({ children }) => {
  const { colorLeft } = useBackgroundColor()
  const slider = useContext(SliderContext)

  return (
    <SLeft
      transition={{ duration: 0.5 }}
      variants={
        slider.direction === -1 && slider.pathname === '/fale-com-a-gente'
          ? variant2
          : variant
      }
      initial='initial'
      animate='animate'
      exit={slider.pathname !== '/fale-com-a-gente' && 'exit'}
      color={colorLeft}
      custom={slider.direction}
    >
      <Line side='left' />
      {children}
    </SLeft>
  )
}

const Right = ({ children }) => {
  const { colorRight } = useBackgroundColor()
  const slider = useContext(SliderContext)

  return (
    <SRight
      transition={{ duration: 0.5 }}
      variants={variant2}
      initial='initial'
      animate='animate'
      exit={slider.pathname !== '/fale-com-a-gente' && 'exit'}
      color={colorRight}
      custom={slider.direction}
    >
      <Line side='right' />
      {children}
    </SRight>
  )
}

export { Left, Right }

const SLeft = styled(motion.div)`
  width: 50vw;
  height: 100vh;
  background-color: ${props => color[props.color]};

  ${direction('column')};
  ${alignment({ cross: 'flex-end' })};

  position: absolute;
`

const SRight = styled(motion.div)`
  width: 50vw;
  height: 100vh;
  background-color: ${props => color[props.color]};
  right: 0;

  ${direction('column')};
  ${alignment({ cross: 'flex-start' })};

  position: absolute;
`
