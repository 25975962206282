import React from 'react'
import styled from 'styled-components'
import { pm, color } from '../../../styles'

const Title = props => <STitle {...props} />
const Label = props => <SLabel {...props} htmlFor={props.children} />
const Paragraph = props => <SParagraph {...props} />

const STitle = styled.h1`
  width: ${props => pm(props.width)};
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: ${props => (props.weight ? props.weight : 300)};
  font-size: ${props => (props.fontSize ? pm(props.fontSize) : pm(40))};
  line-height: ${props => (props.lineHeight ? pm(props.lineHeight) : pm(57))};
  position: ${props => (props.relative ? 'relative' : 'absolute')};
  top: ${props => pm(props.top)};
  left: ${props => pm(props.left)};
  color: ${props => (props.color ? color[props.color] : color.black)};
  ${props => props.textAlign && `text-align: ${props.textAlign}`};
`

const SLabel = styled.label`
  width: ${props => pm(props.width)};
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: ${props => (props.weight ? props.weight : 300)};
  font-size: ${props => (props.fontSize ? pm(props.fontSize) : pm(40))};
  line-height: ${props => (props.lineHeight ? pm(props.lineHeight) : pm(57))};
  position: ${props => (props.relative ? 'relative' : 'absolute')};
  top: ${props => pm(props.top)};
  left: ${props => pm(props.left)};
  color: ${props => (props.color ? color[props.color] : color.black)};
  ${props => props.textAlign && `text-align: ${props.textAlign}`};
`

const SParagraph = styled.h2`
  width: ${props => pm(props.width)};
  position: ${props =>
    !props.nonPos && (props.relative ? 'relative' : 'absolute')};
  top: ${props => pm(props.top)};
  left: ${props => (props.left ? pm(props.left) : 0)};
  font-family: ${props =>
    props.sans ? `'IBM Plex Sans', sans-serif;` : `'IBM Plex Serif', serif;`};
  font-style: normal;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-size: ${props => (props.fontSize ? pm(props.fontSize) : pm(18))};
  line-height: ${props => (props.lineHeight ? pm(props.lineHeight) : pm(52))};
  ${props => props.textAlign && `text-align: ${props.textAlign}`};
  color: ${props => (props.color ? color[props.color] : color.black)};
  opacity: ${props => props.opacity};
  transition-duration: ${props => props.transition && `${props.transition}s`};
`

const A = styled.a.attrs(props => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  href: `https://www.${props.href}.com/${props.route ? props.route : ''}`,
}))``

export { Title, Paragraph, Label, A }
