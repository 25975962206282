import { px } from '../styles'

export default [
  {
    path: '/',
    colorLeft: 'greenDark',
    colorRight: 'greenDark',
    X1L: '',
    X2L: '',
    X1R: px(580),
    X2R: px(125),
    colorLineR: 'orangeDark',
    logoColor: 'white',
    logoTick: 'orangeDark',
    menuColor: 'white',
    buttonHover: 'greenLight',
    buttonBackground: 'greenDark',
    buttonColor: 'white',
    activeColor: 'white',
  },
  {
    path: '/o-que-somos',
    colorLeft: 'orangeDark',
    colorRight: 'white',
    X1L: px(7), // 713
    X2L: px(470), // 250
    colorLineL: 'white',
    logoColor: 'white',
    logoTick: 'white',
    menuColor: 'black',
    buttonHover: 'greenLight',
    buttonBackground: 'white',
    buttonColor: 'greenDark',
    activeColor: 'orangeDark',
  },
  {
    path: '/reducao-de-custos-e-tempo',
    colorLeft: 'salmonLight',
    colorRight: 'orangeDark',
    X1L: px(270), // 450
    X2L: px(720), // 0
    colorLineL: 'greenLight',
    X1R: px(580),
    X2R: px(125),
    colorLineR: 'white',
    logoColor: 'greenDark',
    logoTick: 'orangeDark',
    menuColor: 'white',
    buttonHover: 'white',
    buttonBackground: 'orangeDark',
    buttonColor: 'white',
    hoverColor: 'orangeDark',
    activeColor: 'greenDark',
  },
  {
    path: '/foco-no-core-business',
    colorLeft: 'white',
    colorRight: 'greenDark',
    X1L: px(60), // 660
    X2L: px(500), // 220
    colorLineL: 'orangeDark',
    X1R: px(470),
    X2R: '0',
    colorLineR: 'greenLight',
    logoColor: 'greenDark',
    logoTick: 'orangeDark',
    menuColor: 'white',
    buttonHover: 'white',
    buttonBackground: 'greenDark',
    buttonColor: 'white',
    hoverColor: 'greenDark',
    activeColor: 'orangeDark',
  },
  {
    path: '/organizacao-e-execucao',
    colorLeft: 'salmonLight',
    colorRight: 'white',
    X1L: px(270), // 450
    X2L: px(720), // 0
    colorLineL: 'greenLight',
    X1R: px(600),
    X2R: px(130),
    colorLineR: 'greenLight',
    logoColor: 'greenDark',
    logoTick: 'orangeDark',
    menuColor: 'greenDark',
    buttonHover: 'greenLight',
    buttonBackground: 'white',
    buttonColor: 'greenDark',
    activeColor: 'orangeDark',
    sidebarBackgroundColor: 'salmonLight',
  },
  {
    path: '/mais-capacidade-e-eficiencia',
    colorLeft: 'greenDark',
    colorRight: 'orangeDark',
    X1L: px(60), // 660
    X2L: px(510), // 210
    colorLineL: 'orangeDark',
    X1R: px(470),
    X2R: '0',
    colorLineR: 'white',
    logoColor: 'white',
    logoTick: 'orangeDark',
    menuColor: 'white',
    buttonHover: 'white',
    buttonBackground: 'orangeDark',
    buttonColor: 'white',
    hoverColor: 'orangeDark',
    activeColor: 'greenDark',
  },
  {
    path: '/fale-com-a-gente',
    colorLeft: 'greenDark',
    colorRight: 'greenDark',
    X1L: '',
    X2L: '',
    X1R: px(632),
    X2R: px(177),
    colorLineR: 'orangeDark',
    logoColor: 'white',
    logoTick: 'orangeDark',
    menuColor: 'white',
    activeColor: 'orangeDark',
  },
]
