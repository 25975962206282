import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { pm, color } from '../../../styles'
import { Blanc, Icon, Rectangle, Cellphone } from '../components'
import { Title, Paragraph } from '../components/Texts'
import Line from '../components/SvgLine'
import { Helmet } from 'react-helmet'

export default forwardRef((_, ref) => (
  <Page ref={ref}>
    <Helmet>
      <title>Mais Capacidade e Eficiência.</title>
      <meta
        name='description'
        content='Com a automação das rotinas financeiras, você ganha mais capacidade para focar em ações estratégicas para o sucesso de sua empresa.'
      />
      <link rel='canonical' href={window.location.href} />
    </Helmet>
    <Line x1={375} x2={-170} stroke='white' />
    <Blanc top={135} height={395} color='orangeDark' />

    <Title top={135} lineHeight={52} left={24} color='white'>
      Mais Capacidade <br /> e Eficiência
    </Title>
    <Paragraph top={268} left={24} lineHeight={26} width={327} color='white'>
      Com a automação das rotinas financeiras, você ganha mais capacidade para
      focar em ações estratégicas para o sucesso de sua empresa.
    </Paragraph>

    <Icon top={427} left={24} name='six1' />
    <Icon top={427} left={140} name='six2' />
    <Icon top={427} left={255} name='six3' />

    <Blanc top={590} left={0} width={375}>
      <Line x1={280} x2={30} stroke='orangeDark' />
      <Blanc top={280} height={200} color='greenDark' />
    </Blanc>
    <Rectangle
      top={631.6}
      left={47.82}
      width={288.18}
      height={413.15}
      opacity={0.2}
    />

    <Cellphone top={550} left={39} name='page6' />
  </Page>
))

const Page = styled.div`
  width: 100%;
  height: ${pm(1099)};

  position: relative;

  background-color: ${color.orangeDark};
`
