import React, { useState } from 'react'
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import { pm } from '../../../../styles'
import { Label } from '../../components/Texts'
import Volta from './components/Volta'
import Passos from './components/Passos'
import { FormContext } from '../../../../utils/formContext'
import axios from 'axios'
import Button from './components/Button'
import {
  valName,
  valEmail,
  valNumber,
  valTel,
} from '../../../../utils/validation.js'

import { Step1, Step2, Step3, Step4, Step5 } from './pages'

const variants = {
  initial: custom => ({
    x: custom > 0 ? pm(380) : pm(-380),
  }),

  animate: { x: 0 },

  exit: custom => ({
    x: custom > 0 ? pm(-380) : pm(380),
  }),
}

const variantNames = { initial: 'initial', animate: 'animate', exit: 'exit' }

export default ({ setOpen, open }) => {
  const [[page, direction], setPage] = useState([0, 0])
  const [activeStep, setActiveStep] = useState(0)
  const [voltarOn, setVoltarOn] = useState(false)
  const [formObj, setFormObj] = useState({})

  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [selected, setSelected] = useState('Serviço')
  const [funcionarios, setFuncionarios] = useState('')
  const [telefone, setTelefone] = useState('')

  const paginate = newDirection => setPage([page + newDirection, newDirection])

  const handleNext = () => {
    paginate(1)
    setActiveStep(prev => prev + 1)
  }

  const handlePrev = () => {
    paginate(-1)
    setActiveStep(prev => prev - 1)
  }

  const enviar = async () => {
    const obj = {
      nome,
      email,
      selected,
      funcionarios,
      telefone,
    }
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/lead`, obj)
    console.log(res.data)
  }

  const handleClick = () => {
    if (activeStep === 0) {
      if (valName(nome)) {
        setVoltarOn(true)
        handleNext()
      }
    } else if (activeStep === 1) {
      if (valEmail(email)) {
        handleNext()
      }
    } else if (activeStep === 2) {
      handleNext()
    } else if (activeStep === 3) {
      if (valNumber(funcionarios)) {
        handleNext()
      }
    } else if (activeStep === 4) {
      if (valTel(telefone)) {
        setActiveStep(1)
        setVoltarOn(false)
        setOpen(!open)
        setNome('')
        setEmail('')
        setSelected('Serviço')
        setFuncionarios('')
        setTelefone('')
        enviar()
      }
    }
  }

  const obj = {
    activeStep,
    variants,
    direction,
    variantNames,
    enviar,
    handleNext,
    handlePrev,
    voltarOn,
    setVoltarOn,
    formObj,
    setFormObj,
    titles,
    nome,
    setNome,
    email,
    setEmail,
    selected,
    setSelected,
    funcionarios,
    setFuncionarios,
    telefone,
    setTelefone,
  }

  return (
    <Container>
      <FormContext.Provider value={obj}>
        <Volta />

        <Label
          weight={500}
          fontSize={18}
          lineHeight={24}
          top={0}
          color='salmonLight'
          {...variantNames}
          transition={{ duration: 1 }}
          variants={variants}
          custom={direction}
        >
          {titles[activeStep]}
        </Label>

        <AnimatePresence initial={false} custom={direction}>
          <Render key={activeStep} activeStep={activeStep} />
        </AnimatePresence>

        <Passos
          activeStep={activeStep}
          variants={variants}
          direction={direction}
          down={activeStep === 2}
        />

        <Button onClick={handleClick} />
      </FormContext.Provider>
    </Container>
  )
}

const titles = ['Nome', 'E-mail', 'Segmento', 'Funcinários', 'Telefone']
const pages = [<Step1 />, <Step2 />, <Step3 />, <Step4 />, <Step5 />]

const Render = ({ activeStep }) => pages[activeStep]

const Container = styled.div`
  width: ${pm(317)};
  height: ${pm(234)};
  position: absolute;
  top: ${pm(382)};
  left: ${pm(28)};
  position: absolute;
  overflow: hidden;
`
