import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { pm, alignment, direction, color } from '../../../../../styles'

const variants = {
  initial: {
    opacity: 0,
    pathLength: 0,
    fill: 'rgba(255, 255, 255, 0)',
  },
  animate: {
    opacity: 1,
    pathLength: 1,
    fill: 'rgba(255, 255, 255, 1)',
  },
}

const transitions = {
  default: { duration: 0.6, ease: 'easeInOut' },
  fill: { duration: 0.6, ease: [1, 0, 0.8, 1] },
}

export default ({ active, setActive, n }) => (
  <Container checked={active} onClick={() => setActive(n)}>
    {active && (
      <Item
        width='41'
        height='31'
        viewBox='0 0 41 31'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <Path
          d='M13.0296 24.4579L3.30983 14.818L0 18.0776L13.0296 31L41 3.25951L37.7135 0L13.0296 24.4579Z'
          initial='initial'
          animate='animate'
          variants={variants}
          transition={transitions}
        />
      </Item>
    )}
  </Container>
)

const Container = styled.div`
  width: ${pm(56)};
  height: ${pm(56)};
  display: flex;
  place-content: center;
  overflow: hidden;

  background: ${props => (props.checked ? color.orangeDark : color.checkbox)};

  ${direction()};
  ${alignment()};
`

const Item = styled(motion.svg)`
  width: 60%;
  overflow: visible;
  stroke: #fff;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
`

const Path = styled(motion.path)`
  width: 100%;
  height: 100%;
`
