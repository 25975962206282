import React from 'react'
import styled from 'styled-components'
import { color, alignment, direction, px } from '../styles'
import useDimensions from '../utils/useDimensions'
import { Blanc, Line } from '../components'
import { Title, Paragraph } from '../components/Texts'
import { useHistory } from 'react-router-dom'

export default () => {
  const history = useHistory()
  const { width, height } = useDimensions()

  const refresh = () => {
    history.push('/')
    window.location.reload(false)
  }

  return (
    <Container>
      <Line stroke='white' />
      <Page width={width} height={height}>
        <Blanc
          width={740}
          height={570}
          left={360}
          top={80}
          color='greenLight'
        />
        <Title color='white'>Ooops,</Title>

        <Title fontWeight={900} top={260} fontSize={244} color='white'>
          404
        </Title>

        <Paragraph top={450} left={440} color='white' textAlign='center'>
          Não conseguimos encontrar a página que você está procurando.
        </Paragraph>
        <Button onClick={() => refresh()}>Ok, Entendi</Button>
      </Page>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${color.greenLight};
  right: 0;

  ${direction()};
  ${alignment()};

  position: absolute;

  z-index: 1;
  overflow: hidden;
`

const Page = styled.div`
  width: ${props => `${props.width * 2}px`};
  height: ${props => `${props.height}px`};

  ${props => props.left && `border-right: none`};
  ${props => props.right && `border-left: none`};
  /* border: 2px solid ${color.darkyellow}; */

  @media (min-width: 1440px) and (min-height: 900px) {
    width: 1440px;
    height: 900px;
  }

  position: relative;
  padding-top: ${px(100)};
  padding-bottom: ${px(100)};

  ${direction('column')};
  ${alignment({ main: 'space-between' })};
`

const Button = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;

  display: inline-block;
  background: ${color.greenLight};
  color: white;
  font-size: ${px(16)};
  font-weight: 600;

  width: ${px(228)};
  height: ${px(48)};

  ${direction()};
  ${alignment()}

  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${color.white};
    color: ${color.greenLight};
  }
  text-decoration: none;
  cursor: pointer;

  position: absolute;
  top: ${px(568)};
  left: ${px(606)};

  border: ${px(1)} solid rgba(255, 255, 255, 0.6);
`
