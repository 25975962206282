import React from 'react'
import styled from 'styled-components'
import { alignment, direction, color } from '../../../styles'
import qGigante from '../../../assets/q2.svg'

const MobileSideScreen = () => (
  <Container>
    <Q src={qGigante} alt='Logo-big' />
    <Rect />
    <Title>Atenção!</Title>
    <Desc>Posicione o celular na vertical para visualizar o conteúdo.</Desc>
  </Container>
)

const pm = n => `${(n * 100) / 375}vh`

const Rect = styled.div`
  position: absolute;
  top: ${pm(46)};

  width: ${pm(120)};
  height: ${pm(173)};
  border: ${pm(3)} solid ${color.greenLight};
`

const Title = styled.h3`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  font-size: ${pm(34)};
  line-height: ${pm(44)};
  text-align: center;
  color: ${color.salmonLight};
  position: absolute;
  bottom: ${pm(79)};
`

const Desc = styled.p`
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${pm(20)};
  line-height: ${pm(26)};
  text-align: center;
  color: ${color.salmonLight};
  position: absolute;
  bottom: ${pm(40)};
`

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${color.greenDark};
  ${direction()};
  ${alignment()};
`

const Q = styled.img`
  position: absolute;
  top: ${pm(33)};
  left: 39%;
  width: ${pm(115)};
  height: ${pm(156)};
  z-index: 1;
`

export default MobileSideScreen
