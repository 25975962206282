import React from 'react'
import styled from 'styled-components'
import { px, color } from '../../styles'
import Logo from './Logo'
import Menu from '../menu'
import Button from './Button'
import { useLocation } from 'react-router-dom'
import path from '../../utils/paths'

export default () => {
  const { pathname } = useLocation()
  const obj = path.find(p => p.path === pathname)

  const top = (window.innerHeight - 900) / 2 - 120

  return (
    <>
      {obj && (
        <Nav top={window.innerHeight > 1140 && top}>
          <Logo />
          {obj.path !== '/fale-com-a-gente' && <Button />}
          <Menu />
        </Nav>
      )}
    </>
  )
}

const Nav = styled.div`
  width: 100%;
  height: ${px(90)};
  /* border: solid ${color.purple}; */
  z-index: 1;
  position: fixed;

  @media (min-width: 1680px) {
    width: 1680px;
    left: 50%;
    margin-left: -840px;
  }

  top: ${props => props.top && px(props.top)};
`
