import useDimensions from '../utils/useDimensions'

const color = {
  // American Pallet
  green: '#55efc4',
  darkgreen: '#00b894',
  blue: '#a29bfe',
  darkblue: '#6c5ce7',
  orange: '#fab1a0',
  darkorange: '#e17055',

  // Aussie Pallet
  yellow: '#f6e58d',
  darkyellow: '#f9ca24',
  gray: '#95afc0',
  darkgray: '#535c68',
  purple: '#e056fd',
  darkpurple: '#be2edd',

  // Quilha
  greenDark: '#0F3A35',
  greenLight: '#45B2AC',
  salmonLight: '#F7E9EA',
  orangeDark: '#E25C25',
  orangeMedium: '#F48B60',

  orchid: '#2d3436',
  white: '#ffffff',
  black: '#000000',
  checkbox: 'rgba(255, 255, 255, 0.6)',
}

const direction = (x = 'row') =>
  `
  display: flex;
  flex-direction: ${x};
`

const alignment = ({ main = 'center', cross = 'center' } = {}) =>
  `
  justify-content: ${main};
  align-items: ${cross};
`

const { width, height } = useDimensions()

const px = n =>
  width * 2 >= 1440 && height >= 900
    ? `${n}px`
    : `${(n * (width * 2)) / 1440}px`

const pm = n => `${(n * 100) / 375}vw`

export { color, px, pm, direction, alignment }
