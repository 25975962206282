import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import Button from './Button'
import Menu from './Menu'
import MenuItem from './Item'
import { pm, color, direction, alignment } from '../../../../styles'

const menu = [
  'Início',
  'O que somos',
  'Redução de custo e tempo',
  'Foco no core e business',
  'Organização e execução',
  'Mais Capacidade e eficiência',
  1,
]

export default ({ onClick, pathname }) => {
  const [menuOpen, setmenuOpen] = useState(false)

  const onMenuClick = () => setmenuOpen(!menuOpen)
  const onLinkClick = index => {
    onClick(index)
    setmenuOpen(false)
  }

  const menuItems = menu.map((val, index) =>
    index <= 5 ? (
      <MenuItem
        key={index}
        delay={`${index * 0.05}s`}
        onClick={() => onLinkClick(index)}
      >
        {val}
      </MenuItem>
    ) : (
      <FaleButton
        onClick={() => onLinkClick(index)}
        key={index}
        delay={`${index * 0.05}s`}
      >
        Fale com a gente
      </FaleButton>
    )
  )

  return (
    <>
      <Button
        pathname={pathname}
        open={menuOpen}
        onClick={() => onMenuClick()}
      />

      <Menu open={menuOpen}>{menuItems}</Menu>
    </>
  )
}

const appear = keyframes` 
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const FaleButton = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;

  display: inline-block;
  background: ${color.orangeDark};
  color: white;
  font-size: ${pm(16)};

  width: ${pm(297)};
  height: ${pm(60)};

  ${direction()};
  ${alignment()}

  text-decoration: none;

  position: relative;
  left: calc(50% - ${pm(148.5)});

  border: ${pm(1)} solid rgba(255, 255, 255, 0.6);

  opacity: 0;
  animation: 1s ${appear} forwards;
  animation-delay: ${props => props.delay};

  margin-top: ${pm(35)};
  margin-bottom: ${pm(30)};
`
