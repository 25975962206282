import React from 'react'
import { Left, Right } from '../components/pages/Pages'
import { Title, Paragraph } from '../components/Texts'
import { Blanc, Rectangle, Icon } from '../components'
import styled from 'styled-components'
import { px } from '../styles'
import Device from '../assets/devices.png'
import { Helmet } from 'react-helmet'

export default () => (
  <>
    <Helmet>
      <title>Redução de custos e tempo</title>
    </Helmet>
    <Left>
      <Blanc color='salmonLight' width={450} height={375} top={120} left={50} />
      <Title top={158} left={60} color='orangeDark' width={386} fontSize={44}>
        Redução de <br /> custos e tempo
      </Title>

      <Paragraph top={301} left={60} lineHeight={30} width={403}>
        Tenha um financeiro estruturado com alta confiabilidade e a uma fração
        do custo de um setor financeiro interno.
      </Paragraph>

      <Icon top={321} left={525} name='three1' />
      <Icon top={495} left={525} name='three2' />
      <Icon top={669} left={525} name='three3' />
    </Left>

    <Right>
      <Blanc color='orangeDark' width={450} height={650} top={160} left={190} />
      <Rectangle
        width={392}
        height={562}
        top={207}
        left={227}
        color='orangeMedium'
      />
      <Devices src={Device} alt='Devices' />
    </Right>
  </>
)

const Devices = styled.img`
  width: ${px(495)};
  height: ${px(505)};

  position: absolute;
  top: ${px(270)};
  left: ${px(71)};
`
