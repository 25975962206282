import React from 'react'
import { Left, Right } from './PageContainer'
import InnerContainer from './InnerContainer'

const PageLeft = ({ children }) => {
  return (
    <Left>
      <InnerContainer>{children}</InnerContainer>
    </Left>
  )
}

const PageRight = ({ children }) => {
  return (
    <Right>
      <InnerContainer>{children}</InnerContainer>
    </Right>
  )
}

export { PageLeft as Left, PageRight as Right }
