import React from 'react'
import styled from 'styled-components'
import { color, pm } from '../../../styles'

export default ({ x1, x2, stroke }) => (
  <Svg>
    <line
      x1={pm(x1)}
      x2={pm(x2)}
      y1='0'
      y2='100%'
      stroke={color[stroke]}
      strokeWidth='2'
    />
  </Svg>
)

const Svg = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;

  /* border: solid ${color.darkyellow}; */
`
