import React from 'react'
import { Left, Right } from '../components/pages/Pages'
import { Blanc, Cellphone, Rectangle, Icon } from '../components'
import { Title, Paragraph } from '../components/Texts'
import { Helmet } from 'react-helmet'

export default () => (
  <>
    <Helmet>
      <title>Mais Capacidade e Eficiência</title>
    </Helmet>
    <Left>
      <Blanc width={431} height={635} top={228} left={130} color='greenDark' />
      <Rectangle top={267} left={130} width={392} height={562} opacity={0.2} />
      <Cellphone top={156} left={123} name='page6' />
    </Left>

    <Right>
      <Blanc width={431} height={355} top={128} left={130} color='orangeDark' />
      <Title top={158} left={60} color='white'>
        Mais Capacidade <br /> e Eficiência
      </Title>

      <Paragraph top={301} left={60} lineHeight={30} width={349} color='white'>
        Com a automação das rotinas financeiras, você ganha mais capacidade para
        focar em ações estratégicas para o sucesso de sua empresa.
      </Paragraph>

      <Icon top={321} left={560} name='six1' />
      <Icon top={503} left={560} name='six2' />
      <Icon top={685} left={560} name='six3' />
    </Right>
  </>
)
