import React from 'react'
import useDimensions from '../../utils/useDimensions'
import styled from 'styled-components'
import { color } from '../../styles'

export default ({ children }) => {
  const { width, height } = useDimensions()

  return (
    <Container width={width} height={height}>
      {children}
    </Container>
  )
}

const Container = styled.div`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  /* border: 1px solid ${color.darkblue}; */
  position: absolute;

  @media (min-width: 1440px) and (min-height: 900px) {
    width: 720px;
    height: 900px;
  }
`
