import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import Button from './Button'
import Menu from './Menu'
import Item from './Item'
import { px, color, direction, alignment } from '../../styles'
import { useHistory } from 'react-router-dom'

const menu = [
  'Início',
  'O que somos',
  'Redução de custo e tempo',
  'Foco no core e business',
  'Organização e execução',
  'Mais Capacidade e eficiência',
  1,
]

const paths = [
  '/',
  '/o-que-somos',
  '/reducao-de-custos-e-tempo',
  '/foco-no-core-business',
  '/organizacao-e-execucao',
  '/mais-capacidade-e-eficiencia',
  '/fale-com-a-gente',
]

export default ({ onClick, pathname }) => {
  const [menuOpen, setmenuOpen] = useState(false)
  const history = useHistory()

  const onMenuClick = () => setmenuOpen(!menuOpen)
  const onLinkClick = index => {
    setmenuOpen(false)
    history.push(paths[index])
  }

  const menuItems = menu.map((val, index) =>
    index <= 5 ? (
      <Item
        key={index}
        delay={`${index * 0.05}s`}
        onClick={() => onLinkClick(index)}
      >
        {val}
      </Item>
    ) : (
      <FaleButton
        onClick={() => onLinkClick(index)}
        key={index}
        delay={`${index * 0.05}s`}
      >
        Fale com a gente
      </FaleButton>
    )
  )

  return (
    <>
      <Button
        pathname={pathname}
        open={menuOpen}
        onClick={() => onMenuClick()}
      />

      <Menu open={menuOpen}>
        <ItemContainer>{menuItems}</ItemContainer>
      </Menu>
    </>
  )
}

const ItemContainer = styled.div`
  /* width: ${px(490)}; */
  height: ${px(588)};

  /* border: solid ${color.orangeDark}; */

  position: absolute;
  top: ${px(195)};
  left: calc(50% - ${px(237.5)});

  ${direction('column')};
  ${alignment({ main: 'space-between' })};
`

const appear = keyframes` 
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const FaleButton = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;

  display: inline-block;
  background: ${color.orangeDark};
  color: white;
  font-size: ${px(16)};

  width: ${px(297)};
  height: ${px(60)};

  ${direction()};
  ${alignment()}

  text-decoration: none;

  position: relative;

  border: ${px(1)} solid rgba(255, 255, 255, 0.6);

  opacity: 0;
  animation: 1s ${appear} forwards;
  animation-delay: ${props => props.delay};

  margin-top: ${px(55)};

  &:hover {
    background: transparent;
  }

  cursor: pointer;
`
