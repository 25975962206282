import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { pm, color } from '../../../styles'
import { Title, Paragraph } from '../components/Texts'
import { Icon, Blanc, Rectangle } from '../components'
import Line from '../components/SvgLine'
import Device from '../../../assets/devices-mobile.png'
import { Helmet } from 'react-helmet'

export default forwardRef((_, ref) => (
  <Page ref={ref}>
    <Helmet>
      <title>Redução de custos e tempo.</title>
      <meta
        name='description'
        content='Tenha um financeiro estruturado com alta confiabilidade e a uma fração do custo de um setor financeiro interno.'
      />
      <link rel='canonical' href={window.location.href} />
    </Helmet>
    <Line x1={375} x2={-160} stroke='greenLight' />
    <Blanc top={175} height={340} color='salmonLight' />
    <Title top={135} left={24} color='orangeDark' width={327}>
      Redução de <br /> custos e tempo
    </Title>
    <Paragraph top={268} left={24} lineHeight={30} width={317}>
      Tenha um financeiro estruturado com alta confiabilidade e a uma fração do
      custo de um setor financeiro interno.
    </Paragraph>
    <Icon top={417} left={24} name='three1' />
    <Icon top={417} left={125.83} name='three2' />
    <Icon top={417} left={250.83} name='three3' />
    <Blanc width={375} top={600} height={509} color='orangeDark'>
      <Line x1={280} x2={30} stroke='white' />
      <Blanc top={285} height={200} color='orangeDark' />
    </Blanc>
    <Rectangle
      top={651.6}
      left={47.82}
      width={288.18}
      height={413.15}
      color='orangeMedium'
    />
    <Cellphone src={Device} alt='cellphone' />
  </Page>
))

const Page = styled.div`
  width: 100%;
  height: ${pm(1109)};

  position: relative;

  background-color: ${color.salmonLight};
`

const Cellphone = styled.img`
  width: ${pm(235.98)};
  height: ${pm(476.34)};
  position: absolute;
  top: ${pm(570)};
  left: ${pm(39)};
`
