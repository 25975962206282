import React, { useEffect } from 'react'
import styled from 'styled-components'
import { color, alignment, direction, px } from '../styles'
import useDimensions from '../utils/useDimensions'
import { Blanc, Rectangle, Line } from '../components'
import { Title, Paragraph } from '../components/Texts'
import qGigante from '../assets/q.svg'

export default ({ open, setOpen }) => {
  const { width, height } = useDimensions()

  useEffect(() => {
    if (open) document.body.style.overflow = 'hidden'
    return () => (document.body.style.overflow = 'unset')
  }, [open])

  return (
    <Container>
      <Line stroke='white' />
      <Page width={width} height={height}>
        <Blanc
          width={440}
          height={530}
          top={120}
          left={500}
          color='orangeDark'
        />

        <Title color='salmonLight' top={130} left={500}>
          Legal, deu tudo certo!
        </Title>

        <Rectangle
          width={133.75}
          height={191.75}
          color='orangeMedium'
          top={234.33}
          left={663.61}
          border={3}
        />

        <Q src={qGigante} alt='Logo' />

        <Paragraph
          color='salmonLight'
          top={490}
          left={531}
          width={379}
          textAlign='center'
        >
          Seus dados para contato foram enviados e logo a gente vai falar com
          você.
        </Paragraph>
        <Button onClick={() => setOpen(!open)}>Ok, Entendi</Button>
      </Page>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${color.orangeDark};
  right: 0;

  ${direction()};
  ${alignment()};

  position: absolute;

  z-index: 1;
  overflow: hidden;
`

const Page = styled.div`
  width: ${props => `${props.width * 2}px`};
  height: ${props => `${props.height}px`};

  ${props => props.left && `border-right: none`};
  ${props => props.right && `border-left: none`};
  /* border: 2px solid ${color.darkyellow}; */

  @media (min-width: 1440px) and (min-height: 900px) {
    width: 1440px;
    height: 900px;
  }

  position: relative;
`

const Q = styled.img`
  width: ${px(134.09)};
  height: ${px(179.47)};

  position: absolute;
  top: ${px(219.32)};
  left: ${px(633.59)};
`

const Button = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;

  display: inline-block;
  background: ${color.orangeDark};
  color: white;
  font-size: ${px(16)};
  font-weight: 600;

  width: ${px(228)};
  height: ${px(48)};

  ${direction()};
  ${alignment()}

  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${color.white};
    color: ${color.orangeDark};
  }
  text-decoration: none;
  cursor: pointer;

  position: absolute;
  top: ${px(568)};
  left: ${px(606)};

  border: ${px(1)} solid rgba(255, 255, 255, 0.6);
`
