import React, { useContext } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { px, alignment, direction } from '../../../styles'
import { Paragraph } from '../../../components/Texts'
import { FormContext } from '../../../utils/formContext'
import Svg from '../../../assets/arrow_volta.svg'
import { AnimatePresence } from 'framer-motion'

const variant = {
  initial: { x: px(80) },
  animate: { x: 0 },
  exit: { x: px(80) },
}

const transition = {
  duration: 0.2,
}

export default () => {
  const form = useContext(FormContext)

  const handleClick = () => {
    if (form.activeStep === 1) {
      form.setVoltarOn(false)
      form.handlePrev()
    } else form.handlePrev()
  }

  return (
    <AnimatePresence>
      {form.voltarOn && (
        <Volta
          onClick={handleClick}
          initial='initial'
          animate='animate'
          exit={form.voltarOn && 'exit'}
          variants={variant}
          transition={transition}
        >
          <img src={Svg} alt='back' />
          <Paragraph
            relative
            bold
            sans
            lineHeight={21}
            fontSize={16}
            color='greenLight'
          >
            Voltar
          </Paragraph>
        </Volta>
      )}
    </AnimatePresence>
  )
}

const Volta = styled(motion.div)`
  width: ${px(80)};
  height: ${px(24)};

  ${direction()};
  ${alignment({ main: 'space-between' })};

  background-color: transparent;

  position: absolute;
  top: ${px(20)};
  right: 0;

  cursor: pointer;
`
