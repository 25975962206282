import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { px, color, direction, alignment } from '../../styles'
import { useLocation } from 'react-router-dom'
import path from '../../utils/paths'

export default () => {
  const { pathname } = useLocation()
  const obj = path.find(p => p.path === pathname)

  return (
    <SButton
      to='/fale-com-a-gente'
      hover={obj.buttonHover}
      backgroundcolor={obj.buttonBackground}
      color={obj.buttonColor}
      hovercolor={obj.hoverColor}
    >
      Fale com a gente
    </SButton>
  )
}

const SButton = styled(Link)`
  font-family: 'IBM Plex Sans', sans-serif;

  display: inline-block;
  background: ${props => color[props.backgroundcolor]};
  color: ${props => color[props.color]};
  font-size: ${px(14)};
  font-weight: bold;

  width: ${px(211)};
  height: ${px(36)};

  ${direction()};
  ${alignment()}

  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${props => props.hover && color[props.hover]};
    color: ${props => props.hovercolor && color[props.hovercolor]};
  }

  text-decoration: none;
  cursor: pointer;

  position: absolute;
  top: ${px(27)};
  right: ${px(105)};

  border: ${px(2)} solid ${props => color[props.color]};
`
