import React, { forwardRef, useState } from 'react'
import styled from 'styled-components'
import { pm, color } from '../../../styles'
import { Blanc } from '../components'
import { Title, Paragraph } from '../components/Texts'
import Line from '../components/SvgLine'
import Form from '../components/Form'
import Modal from '../components/Modal'
import { Helmet } from 'react-helmet'

export default forwardRef((_, ref) => {
  const [open, setOpen] = useState(false)

  return (
    <Page ref={ref}>
      <Helmet>
        <title>Fale com a gente.</title>
        <meta name='description' content='Formulário de contato.' />
        <link rel='canonical' href={window.location.href} />
      </Helmet>
      <Line x1={350} x2={-45} stroke='orangeDark' />
      <Blanc top={160} height={475} color='greenDark' />
      <Title lineHeight={52} top={135} left={28} color='salmonLight'>
        Fale <br /> com a gente
      </Title>
      <Paragraph
        top={268}
        width={300}
        left={28}
        color='salmonLight'
        lineHeight={26}
      >
        Ficou interessado? <br /> Deixe seus dados e a gente entra em contato{' '}
        rapidinho com você.
      </Paragraph>

      <Form setOpen={setOpen} open={open} />

      {open && <Modal open={open} setOpen={() => setOpen(!open)} />}
    </Page>
  )
})

const Page = styled.div`
  width: 100%;
  height: ${pm(812)};

  /* border: 1px solid ${color.yellow}; */

  position: relative;

  background-color: ${color.greenDark};
`
