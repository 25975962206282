import React, { useContext, useState, useEffect } from 'react'
import CheckList from '../components/CheckList'
import { FormContext } from '../../../../../utils/formContext'

export default () => {
  const form = useContext(FormContext)
  const arr = ['Serviço', 'Comércio', 'Ambos']
  const [active, setActive] = useState(arr.indexOf(form.selected))

  useEffect(() => {
    form.setSelected(arr[active])
  }, [active, arr, form])

  return (
    <CheckList
      arr={arr}
      active={active}
      setActive={setActive}
      name='checklist'
      label='Segmento'
    />
  )
}
