import React from 'react'
import styled from 'styled-components'
import { pm, color } from '../../../../styles'
import Logo from './Logo'
import Button from './Button'
import path from '../../../../utils/pathsMobile'
import Menu from '../Menu'

export default ({ onClick, pathname }) => {
  const obj = path.find(p => p.path === pathname)

  return (
    <Nav color={obj.navBackground}>
      <Logo pathname={pathname} />
      <Button obj={obj} onClick={onClick} />
      <Menu onClick={onClick} pathname={pathname} />
    </Nav>
  )
}

const Nav = styled.div`
  z-index: 1;

  width: 100%;
  height: ${pm(107)};
  position: fixed;
  top: 0;
  /* border: solid ${color.darkpurple}; */
  background-color: ${props => color[props.color]};
`
