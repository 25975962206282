import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { pm, color } from '../../../styles'
import { Icon, Blanc, Rectangle, Cellphone } from '../components'
import { Title, Paragraph } from '../components/Texts'
import Line from '../components/SvgLine'
import { Helmet } from 'react-helmet'

export default forwardRef((_, ref) => (
  <Page ref={ref}>
    <Helmet>
      <title>Foco no Core Business.</title>
      <meta
        name='description'
        content='Tenha mais tempo para focar no que a sua empresa faz de melhor.'
      />
      <link rel='canonical' href={window.location.href} />
    </Helmet>
    <Line x1={375} x2={-217} stroke='greenLight' />
    <Blanc top={205} height={415} color='greenDark' />
    <Title top={135} left={24} color='greenLight' width={327} fontSize={44}>
      Foco no Core Business
    </Title>
    <Paragraph
      top={268}
      left={24}
      lineHeight={30}
      width={300}
      color='white'
      opacity={0.7}
    >
      Tenha mais tempo para focar no que a sua empresa faz de melhor; Com a
      Quilha Rotinas Financeiras, você não precisa mais gastar tempo com tarefas
      financeiras que o sugam tempo e esforço que você poderia estar dedicando a
      outras partes da sua empresa.
    </Paragraph>
    <Icon top={524} left={24} name='four1' />
    <Icon top={524} left={125.83} name='four2' />
    <Icon top={524} left={250.83} name='four3' />
    <Blanc top={670} color='white' width={375} height={509}>
      <Line x1={280} x2={30} stroke='orangeDark' />
      <Blanc top={280} height={200} color='white' />
    </Blanc>
    <Rectangle
      top={711.6}
      left={47.82}
      width={288.18}
      height={413.15}
      color='salmonLight'
    />
    <Cellphone top={630} left={39} name='page4' />
  </Page>
))

const Page = styled.div`
  width: 100%;
  height: ${pm(1169)};

  position: relative;

  background-color: ${color.greenDark};
`
