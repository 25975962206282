import React from 'react'
import styled from 'styled-components'
import Iphone from '../assets/iphone.png'
import { px, direction, alignment } from '../styles'

export default props => (
  <Cellphone {...props}>
    <Video autoPlay loop muted inline>
      <Source src={require(`../assets/${props.name}.mp4`)} type='video/mp4' />
    </Video>
    <Frame src={Iphone} alt='Frame' />
  </Cellphone>
)

const Frame = styled.img`
  width: 100%;
  height: 100%;
`

const Cellphone = styled.div`
  position: absolute;
  top: ${props => px(props.top)};
  left: ${props => px(props.left)};

  width: ${px(321)};
  height: ${px(647.94)};

  ${alignment()};
  ${direction()};
`

const Source = styled.source`
  width: ${px(278)};
  height: ${px(602)};
  position: absolute;
  border-radius: ${px(30)};
`

const Video = styled.video`
  width: ${px(278)};
  height: ${px(602)};
  position: absolute;
  border-radius: ${px(30)};
`
