import React from 'react'
// import ReactGA from 'react-ga'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import Global from './styles/Global'
import App from './App'
import { Helmet } from 'react-helmet'

const data = {
  '@context': 'https://schema.org',
  '@type': 'AccountingService',
  name: 'Quilha Rotinas Financeiras',
  image: '',
  '@id': '',
  url: 'https://www.quilharotinas.com/',
  telephone: '',
  address: {
    '@type': 'PostalAddress',
    streetAddress:
      'Sala 138 - Ed Parque Brasília, St. de Indústrias Gráficas, Quadra 3',
    addressLocality: 'Brasilia',
    postalCode: '70610-410',
    addressCountry: 'BR',
  },
  openingHoursSpecification: {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    opens: '09:00',
    closes: '18:00',
  },
  sameAs: 'https://instagram.com/quilharotinas',
}
 
// export const initGA = () => ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE)

ReactDOM.render(
  <Router>
    <Helmet>
      <link
        href='https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap'
        rel='stylesheet'
      />
      <script type='application/ld+json'>{JSON.stringify(data)}</script>
    </Helmet>
    <Global />
    <App />
  </Router>,
  document.getElementById('root')
)
