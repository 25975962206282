import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { pm, alignment, direction } from '../../../../../styles'
import { FormContext } from '../../../../../utils/formContext'
import Checkbox from './Checkbox'
import { Title } from '../../../components/Texts'

export default ({ active, setActive, arr }) => {
  const form = useContext(FormContext)

  return (
    <Container
      {...form.variantNames}
      transition={{ duration: 1 }}
      variants={form.variants}
      custom={form.direction}
    >
      <Left>
        <Checkbox active={active === 0} n={0} setActive={setActive} arr={arr} />
        <Title fontSize={20} lineHeight={28} color='white' relative>
          {arr[0]}
        </Title>
      </Left>
      <Middle>
        <Checkbox active={active === 1} n={1} setActive={setActive} arr={arr} />
        <Title fontSize={20} lineHeight={28} color='white' relative>
          {arr[1]}
        </Title>
      </Middle>
      <Right>
        <Checkbox active={active === 2} n={2} setActive={setActive} arr={arr} />
        <Title fontSize={20} lineHeight={28} color='white' relative>
          {arr[2]}
        </Title>
      </Right>
    </Container>
  )
}

const AlignMixin = css`
  ${direction('column')};
  ${alignment({ main: 'space-between' })};
`

const Container = styled(motion.div)`
  width: 100%;
  height: ${pm(95)};

  position: absolute;
  top: ${pm(32)};

  ${direction()};
  ${alignment({ main: 'space-between' })};
`

const Left = styled.div`
  height: 100%;
  width: ${pm(67)};
  ${AlignMixin};
`
const Middle = styled.div`
  height: 100%;
  width: ${pm(86)};
  ${AlignMixin};
`
const Right = styled.div`
  height: 100%;
  width: ${pm(63)};
  ${AlignMixin};
`
