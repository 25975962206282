import React from 'react'
import styled from 'styled-components'
import { pm, color } from '../../../styles'

export default props => <Blanc {...props}>{props.children}</Blanc>

const Blanc = styled.div`
  width: ${props => (props.width ? pm(props.width) : pm(375))};
  height: ${props => (props.height ? pm(props.height) : pm(572.25))};
  background-color: ${props =>
    props.color ? color[props.color] : color.greenDark};

  position: absolute;
  top: ${props => (props.top ? pm(props.top) : pm(203))};
  left: ${props => (props.left ? pm(props.left) : 0)};
`
