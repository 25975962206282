import React, { useContext } from 'react'
import styled from 'styled-components'
import { px, alignment, direction, color } from '../../../styles'
import { FormContext } from '../../../utils/formContext'
import Vai from '../../../assets/arrow_vai.svg'
import Check from '../../../assets/check.svg'
import { motion } from 'framer-motion'

export default ({ onClick }) => {
  const form = useContext(FormContext)

  return (
    <>
      {form.activeStep === 4 ? (
        <Button onClick={onClick} width={214}>
          <p>Pronto, concluir</p>
          <Image src={Check} alt='check' />
        </Button>
      ) : (
        <Button onClick={onClick} width={250}>
          <p>Tecle enter ou clique</p> <Image src={Vai} alt='forward' />
        </Button>
      )}
    </>
  )
}

const Button = styled(motion.div)`
  height: ${px(48)};
  width: ${props => px(props.width)};

  position: absolute;
  top: ${px(136)};
  right: 0;

  font-family: 'IBM Plex Sans', sans-serif;
  display: inline-block;
  color: white;
  font-size: ${px(16)};

  ${direction()};
  ${alignment({ main: 'space-between' })}

  padding-left: ${px(30)};
  padding-right: ${px(30)};

  /* transition: all 0.3s ease-in-out; */

  &:hover {
    background: ${color.greenLight};
  }
  text-decoration: none;
  cursor: pointer;

  position: absolute;
  top: ${px(136)};
  right: 0;

  border: ${px(1)} solid rgba(255, 255, 255, 0.6);
`

const Image = styled.img`
  width: ${px(24)};
  height: ${px(24)};
`
