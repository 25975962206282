import React from 'react'
import styled from 'styled-components'
import { px, color, alignment, direction } from '../styles'
import path from '../utils/paths'
import { useLocation } from 'react-router-dom'

export default () => {
  const location = useLocation()

  return (
    <Sidebar>
      {path.map(item => (
        <Ball
          key={item.path}
          active={location.pathname === item.path}
          activeColor={item.activeColor}
          backgroundColor={
            location.pathname === '/organizacao-e-execucao'
              ? 'salmonLight'
              : 'white'
          }
          opacity={
            location.pathname === '/o-que-somos' ||
            location.pathname === '/organizacao-e-execucao'
              ? 1
              : 0.2
          }
        />
      ))}
    </Sidebar>
  )
}

const Sidebar = styled.div`
  width: ${px(10)};
  height: ${px(130)};

  position: absolute;
  top: calc(50% - ${px(65)});
  right: ${px(30)};

  ${direction('column')};
  ${alignment({ main: 'space-between' })};
`

const Ball = styled.div`
  width: ${px(10)};
  height: ${px(10)};
  background-color: ${props => color[props.backgroundColor]};
  opacity: ${props => props.opacity};

  ${props =>
    props.active &&
    `background-color: ${color[props.activeColor]}; opacity: 1;`};

  border-radius: 50%;
`
