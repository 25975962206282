import React from 'react'
import { Left, Right } from '../components/pages/Pages'
import styled from 'styled-components'
import { color, px, direction, alignment } from '../styles'
import { Link } from 'react-router-dom'
import { Blanc, Rectangle, Cellphone } from '../components'
import { Helmet } from 'react-helmet'
import Hidden from '../components/Hidden.js'

export default () => {
  return (
    <>
      <Hidden />
      <Helmet>
        <title>Bem Vindo.</title>
        <meta
          name='description'
          content='para de forma que o rotinas financeiras da sua empresa mais capacidade tempo
          setor financeiro'
        />
        <link rel='canonical' href={window.location.href} />
      </Helmet>
      <Left>
        <Rectangle top={187} left={67} opacity={0.2} />
        <Title>
          Cuidamos do seu financeiro para que ele rode no piloto automático.
        </Title>
        <Button to='/fale-com-a-gente'>Fale com a gente</Button>
      </Left>
      <Right>
        <Blanc height={725} width={450} top={135} left={125} />
        <Rectangle
          top={267}
          left={166}
          height={562}
          width={392}
          opacity={0.2}
        />
        <Cellphone top={156} left={154} name='page1' />
      </Right>
      <H2>Fale com a gente</H2>
      <HiddenTitle>
        de forma que o rotinas financeiras da sua empresa mais capacidade tempo
        setor financeiro
      </HiddenTitle>
    </>
  )
}

const H2 = styled.h2`
  display: none;
`

const HiddenTitle = styled.h2`
  position: absolute;
  color: transparent;
`

const Title = styled.h1`
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: ${px(49)};
  line-height: ${px(64)};

  color: ${color.greenLight};

  text-shadow: ${px(0)} ${px(4)} ${px(4)} rgba(0, 0, 0, 0.25);

  width: ${px(510)};
  height: ${px(320)};

  position: absolute;
  top: ${px(221)};
  left: ${px(110)};
`

const Button = styled(Link)`
  font-family: 'IBM Plex Sans', sans-serif;

  display: inline-block;
  background: ${color.orangeDark};
  color: white;
  font-size: ${px(16)};

  width: ${px(297)};
  height: ${px(60)};

  ${direction()};
  ${alignment()}

  transition: all 0.3s ease-in-out;

  &:hover {
    background: transparent;
  }
  text-decoration: none;
  cursor: pointer;

  position: absolute;
  top: ${px(528.75)};
  left: ${px(98)};

  border: ${px(1)} solid rgba(255, 255, 255, 0.6);
`
