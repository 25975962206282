import React from 'react'
import styled from 'styled-components'

export default () => (
  <Hidden>
    Cuidamos do seu financeiro para que ele rode no piloto automático. O que
    somos? Uma empresa especializada em rotinas financeiras que surge do ideal
    que o departamento financeiro não deve concorrer com o core business dos
    nossos clientes. Acreditamos que o processo financeiro não precisa ser
    desgastante e que, quando realizado de forma mais assertiva, além de gerar
    economia de tempo gera também tranquilidade às empresas. Nossa solução Uma
    plataforma de automatização de rotinas financeiras criada por especialistas,
    que por meio de tecnologia ajuda empreendedores a terem acesso a um setor
    financeiro estruturado de forma simples e rápida. Redução de custos e tempo
    Tenha um financeiro estruturado com alta confiabilidade e a uma fração do
    custo de um setor financeiro interno. Foco no Core Business Tenha mais tempo
    para focar no que a sua empresa faz de melhor; Com a Quilha Rotinas
    Financeiras, você não precisa mais gastar tempo com tarefas financeiras que
    o sugam tempo e esforço que você poderia estar dedicando a outras partes da
    sua empresa. Organização e Execução Tenha todas as informações financeiras
    da sua empresa na palma da sua mão de forma organizada e fácil. Encontre
    onde está aquele comprovante rapidamente ou se aquela conta foi realmente
    paga. Tenha relatórios detalhados sobre a movimentação bancária da sua
    empresa sem preocupação e informações precisas para realização de análises
    sobre o seu negócio. Mais Capacidade e Eficiência Com a automação das
    rotinas financeiras, você ganha mais capacidade para focar em ações
    estratégicas para o sucesso de sua empresa. Ficou interessado? Deixe seus
    dados e a gente entra em contato rapidinho com você. Brasília, DF, 70610-410
    Sala 138 - Ed Parque Brasília, <br /> St. de Indústrias Gráficas, Quadra 3
    Cuidamos do seu financeiro para que ele rode no piloto automático. O que
    somos? Uma empresa especializada em rotinas financeiras que surge do ideal
    que o departamento financeiro não deve concorrer com o core business dos
    nossos clientes. Acreditamos que o processo financeiro não precisa ser
    desgastante e que, quando realizado de forma mais assertiva, além de gerar
    economia de tempo gera também tranquilidade às empresas. Nossa solução Uma
    plataforma de automatização de rotinas financeiras criada por especialistas,
    que por meio de tecnologia ajuda empreendedores a terem acesso a um setor
    financeiro estruturado de forma simples e rápida. Redução de custos e tempo
    Tenha um financeiro estruturado com alta confiabilidade e a uma fração do
    custo de um setor financeiro interno. Foco no Core Business Tenha mais tempo
    para focar no que a sua empresa faz de melhor; Com a Quilha Rotinas
    Financeiras, você não precisa mais gastar tempo com tarefas financeiras que
    o sugam tempo e esforço que você poderia estar dedicando a outras partes da
    sua empresa. Organização e Execução Tenha todas as informações financeiras
    da sua empresa na palma da sua mão de forma organizada e fácil. Encontre
    onde está aquele comprovante rapidamente ou se aquela conta foi realmente
    paga. Tenha relatórios detalhados sobre a movimentação bancária da sua
    empresa sem preocupação e informações precisas para realização de análises
    sobre o seu negócio. Mais Capacidade e Eficiência Com a automação das
    rotinas financeiras, você ganha mais capacidade para focar em ações
    estratégicas para o sucesso de sua empresa. Ficou interessado? Deixe seus
    dados e a gente entra em contato rapidinho com você. Brasília, DF, 70610-410
    Sala 138 - Ed Parque Brasília, <br /> St. de Indústrias Gráficas, Quadra 3
    Cuidamos do seu financeiro para que ele rode no piloto automático. O que
    somos? Uma empresa especializada em rotinas financeiras que surge do ideal
    que o departamento financeiro não deve concorrer com o core business dos
    nossos clientes. Acreditamos que o processo financeiro não precisa ser
    desgastante e que, quando realizado de forma mais assertiva, além de gerar
    economia de tempo gera também tranquilidade às empresas. Nossa solução Uma
    plataforma de automatização de rotinas financeiras criada por especialistas,
    que por meio de tecnologia ajuda empreendedores a terem acesso a um setor
    financeiro estruturado de forma simples e rápida. Redução de custos e tempo
    Tenha um financeiro estruturado com alta confiabilidade e a uma fração do
    custo de um setor financeiro interno. Foco no Core Business Tenha mais tempo
    para focar no que a sua empresa faz de melhor; Com a Quilha Rotinas
    Financeiras, você não precisa mais gastar tempo com tarefas financeiras que
    o sugam tempo e esforço que você poderia estar dedicando a outras partes da
    sua empresa. Organização e Execução Tenha todas as informações financeiras
    da sua empresa na palma da sua mão de forma organizada e fácil. Encontre
    onde está aquele comprovante rapidamente ou se aquela conta foi realmente
    paga. Tenha relatórios detalhados sobre a movimentação bancária da sua
    empresa sem preocupação e informações precisas para realização de análises
    sobre o seu negócio. Mais Capacidade e Eficiência Com a automação das
    rotinas financeiras, você ganha mais capacidade para focar em ações
    estratégicas para o sucesso de sua empresa. Ficou interessado? Deixe seus
    dados e a gente entra em contato rapidinho com você. Brasília, DF, 70610-410
    Sala 138 - Ed Parque Brasília, <br /> St. de Indústrias Gráficas, Quadra 3
  </Hidden>
)

const Hidden = styled.p`
  display: hidden;
  color: transparent;
  background-color: transparent;
  position: absolute;
`
