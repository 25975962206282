import React from 'react'
import styled, { css } from 'styled-components'
import { pm, color, direction, alignment } from '../../../../styles'
import path from '../../../../utils/pathsMobile'

export default ({ onClick, open, pathname }) => {
  const obj = path.find(p => p.path === pathname)

  return (
    <Container open={open} onClick={onClick}>
      <LineTop open={open} color={obj.strokeColor} />
      <LineMiddle open={open} color={obj.strokeColor} />
      <LineBottom open={open} color={obj.strokeColor} />
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  top: ${pm(60)};
  left: ${pm(310)};
  z-index: 99;
  opacity: 0.9;
  display: flex;
  align-items: center;

  width: ${pm(40)};
  min-height: ${props => (props.open ? pm(40) : pm(21))};

  ${direction('column')};
  ${alignment({ main: 'space-between', cross: 'flex-start' })};
`

const Line = css`
  height: ${pm(2)};
  transition: 'all 0.2s ease';
  transition: 0.3s;
`

const LineTop = styled.div`
  ${Line};
  width: ${pm(40)};
  background-color: ${props => (props.open ? color.white : color[props.color])};

  transform: ${props =>
    props.open ? `translateY(${pm(13)}) rotate(45deg)` : 'none'};
`
const LineMiddle = styled.div`
  ${Line};
  width: ${pm(24)};
  background-color: ${props => (props.open ? color.white : color[props.color])};

  opacity: ${props => (props.open ? 0 : 1)};

  transform: ${props => (props.open ? `translateX(${pm(-10)})` : 'none')};
`
const LineBottom = styled.div`
  ${Line};
  width: ${props => (props.open ? pm(40) : pm(30))};
  background-color: ${props => (props.open ? color.white : color[props.color])};

  transform: ${props =>
    props.open ? `translateY(${pm(-24)}) rotate(-45deg)` : 'none'};
`
