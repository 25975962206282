import ReactGA from "react-ga"
 
const init = () => {
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE, { debug: isDev })
}
 
const sendEvent = (payload) => ReactGA.event(payload)

 
const sendPageview = (path) => {
  ReactGA.set({ page: path })
  ReactGA.pageview(path)
}
 
export default {
  init,
  sendEvent,
  sendPageview,
}