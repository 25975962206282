import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { pm, color } from '../../../styles'
import { Blanc } from '../components'
import { Title, Paragraph } from '../components/Texts'
import { Helmet } from 'react-helmet'

export default forwardRef((_, ref) => (
  <Page ref={ref}>
    <Helmet>
      <title>O que somos.</title>
      <meta
        name='description'
        content='Somos uma empresa especializada em rotinas financeiras.'
      />
      <link rel='canonical' href={window.location.href} />
    </Helmet>

    <Blanc width={375} height={424} top={159} color='salmonLight' />
    <Title left={24} top={132}>
      O que somos?
    </Title>
    <Paragraph lineHeight={28} left={24} width={327} top={223}>
      Uma empresa especializada em rotinas financeiras que surge do ideal que o
      departamento financeiro não deve concorrer com o core business dos nossos
      clientes.
    </Paragraph>
    <Paragraph lineHeight={28} left={24} width={327} top={385}>
      Acreditamos que o processo financeiro não precisa ser desgastante e que,
      quando realizado de forma mais assertiva, além de gerar economia de tempo
      gera também tranquilidade às empresas.
    </Paragraph>
    <Title left={24} top={612}>
      Nossa solução
    </Title>
    <Paragraph lineHeight={28} left={24} height={109} width={327} top={678}>
      Uma plataforma de automatização de rotinas financeiras criada por
      especialistas, que por meio de tecnologia ajuda empreendedores a terem
      acesso a um setor financeiro estruturado de forma simples e rápida.
    </Paragraph>
    <ReadersView>
      <p>
        Acreditamos que o processo financeiro não precisa ser desgastante e que,
        quando realizado de forma mais assertiva, além de gerar economia de
        tempo gera também tranquilidade às empresas.
      </p>
      <h3>Nossa solução</h3>
      <p>
        Uma plataforma de automatização de rotinas financeiras criada por
        especialistas, que por meio de tecnologia ajuda empreendedores a terem
        acesso a um setor financeiro estruturado de forma simples e rápida.
      </p>
      <h3>Redução de custos e tempo</h3>
      <p>
        Tenha um financeiro estruturado com alta confiabilidade e a uma fração
        do custo de um setor financeiro interno.
      </p>
      <h3>Foco no Core Business</h3>
      <p>
        Tenha mais tempo para focar no que a sua empresa faz de melhor; Com a
        Quilha Rotinas Financeiras, você não precisa mais gastar tempo com
        tarefas financeiras que o sugam tempo e esforço que você poderia estar
        dedicando a outras partes da sua empresa.
      </p>
      <h3>Organização e Execução</h3>
      <p>
        Tenha todas as informações financeiras da sua empresa na palma da sua
        mão de forma organizada e fácil. Encontre onde está aquele comprovante
        rapidamente ou se aquela conta foi realmente paga. Tenha relatórios
        detalhados sobre a movimentação bancária da sua empresa sem preocupação
        e informações precisas para realização de análises sobre o seu negócio.
      </p>
      <h3>Mais Capacidade e Eficiência.</h3>
      <p>
        Com a automação das rotinas financeiras, você ganha mais capacidade para
        focar em ações estratégicas para o sucesso de sua empresa.
      </p>
    </ReadersView>
  </Page>
))

const ReadersView = styled.article`
  position: absolute;
  color: transparent;
`

const Page = styled.div`
  width: 100%;
  height: ${pm(915)};

  position: relative;

  background-color: ${color.white};
`
