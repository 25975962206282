// import { useState, useEffect } from 'react'

// export default () => {
//   const [width, setWidth] = useState(window.innerWidth)
//   const [height, setHeight] = useState(window.innerHeight)

//   if (height < 900 && width === 1440) setWidth(height * 1.6)
//   else if (width < 1440 && height === 900) setHeight(width * 0.625)
//   else if (width < 1440 && width < height * 1.6) setHeight(width * 0.625)
//   else {
//     if (height < width) setWidth(height * 1.6)
//     else setHeight(width * 0.625)
//   }

//   return { width, height }
// }

if (typeof window === 'undefined') {
  global.window = {}
}

export default (width = window.innerWidth, height = window.innerHeight) => {
  let newHeight
  let newWidth

  if (height < 900 && width === 1440) {
    newHeight = height
    newWidth = height * 1.6
  } else if (width < 1440 && height === 900) {
    newWidth = width
    newHeight = width * 0.625
  } else if (width < 1440 && width < height * 1.6) {
    newWidth = width
    newHeight = width * 0.625
  } else {
    if (height < width) {
      newHeight = height
      newWidth = height * 1.6
    } else {
      newWidth = width
      newHeight = width * 0.625
    }
  }

  return { width: newWidth / 2, height: newHeight }
}
